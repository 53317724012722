import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { Router } from "@angular/router";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { EntrepriseService } from "../../../services/entrepriseServices/entreprise/entreprise.service";

@Component({
  selector: "app-verify-entreprise-account",
  templateUrl: "./verify-entreprise-account.component.html",
  styleUrls: ["./verify-entreprise-account.component.css"],
})
export class VerifyEntrepriseAccountComponent implements OnInit {
  verifyAccountError = {
    message: null,
    status: null,
  };
  verifyAccountBtnState: boolean = false;
  verifyAccountSpinnerState: boolean = true;

  constructor(
    private formbulder: FormBuilder,
    private router: Router,
    private entrepriseServices: EntrepriseService
  ) {}

  ngOnInit() {}

  formActiveAccount = this.formbulder.group({
    code: new FormControl("", Validators.required),
  });

  get code() {
    return this.formActiveAccount.get("code");
  }
  activate_entreprie_accounte() {
    if (this.formActiveAccount.valid) {
      // localStorage.setItem(
      //   "users_oid",
      //   String(btoa(String(6)))
      // );
      this.verifyAccountBtnState = true;
      this.verifyAccountSpinnerState = false;
      let verifyAccount = {
        token: this.formActiveAccount.get("code").value,
        users: { oid: Number(atob(localStorage.getItem("users_oid"))) },
      };
      this.entrepriseServices
        .activateEntreprieAccounte(verifyAccount)
        .subscribe({
          next: (response) => {
            this.verifyAccountBtnState = false;
            this.verifyAccountSpinnerState = true;
            if (response["status"] == true) {
              alert(response["message"]);
              this.router.navigate(["entreprise/login"]);
            } else {
              this.verifyAccountBtnState = false;
              this.verifyAccountSpinnerState = true;
              this.verifyAccountError.message = response["message"];
              this.verifyAccountError.status = response["status"];
            }
          },
          error: (err) => {
            this.verifyAccountBtnState = false;
            this.verifyAccountSpinnerState = true;
            console.log(err.error);
          },
        });
    } else {
      alert("Veillez remplis ce champs obligatoire");
    }
  }
}
