import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { RegisterService } from "src/app/services/candidat/register.service";
import { EntrepriseService } from "src/app/services/entrepriseServices/entreprise/entreprise.service";

@Component({
  selector: "app-login-candidat-modal",
  templateUrl: "./login-candidat-modal.component.html",
  styleUrls: ["./login-candidat-modal.component.css"],
})
export class LoginCandidatModalComponent implements OnInit {
  @ViewChild("closeModal")
  closeModal;
  loginForm: FormGroup;
  registerform: FormGroup;
  candidat;
  candidatlog;
  user;
  loginBtnState: boolean = false;
  loginSpinnerState: boolean = true;
  registerSpinnerState: boolean = true;
  errorMessage = {
    status: null,
    message: null,
  };
  errorMessageRegister = {
    status: null,
    message: null,
  };

  messageconfirmpassword;

  constructor(
    private fb: FormBuilder,
    private registerservice: RegisterService,
    private entrepriseServices: EntrepriseService,
    private candidatservice: RegisterService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      identifiant: "",
      pwd: "",
    });

    this.registerform = this.fb.group({
      oid: "",
      nom: new FormControl("", [Validators.required, Validators.maxLength(15)]),
      prenom: ["", Validators.required],
      email: ["", Validators.required],
      password: ["", Validators.required],
      password1: ["", Validators.required],
      civilite: ["", Validators.required],
    });
  }

  onConnexion() {
    // console.log(this.loginForm)
    this.loginBtnState = true;
    this.loginSpinnerState = false;
    let user = {
      identifiant: this.loginForm.value.identifiant,
      password: this.loginForm.value.pwd,
    };

    this.registerservice.connexion_candidat(user).subscribe(
      (response) => {
        this.loginBtnState = false;
        this.loginSpinnerState = true;
        let jwt = response.headers.get("Authorization");
        this.registerservice.saveToken(jwt);

        this.entrepriseServices.find_user().subscribe({
          next: (response1) => {
            this.user = response1;
            // localStorage.setItem("logo", this.users.entrepriseAccount.logo);
            // localStorage.setItem(
            //   "username",
            //   this.user.candidat.nom + " " + this.user.candidat.prenom
            // );
            localStorage.setItem("username", this.user.candidat.nom);
            // console.log(localStorage.getItem("username"))
            localStorage.setItem(
              "candidat_oid",
              String(btoa(String(this.user.candidat.oid)))
            );
            // this.router.navigate(["entreprise/list-offers"]);
            this.closeModal.nativeElement.click();
          },
          error: (err1) => {
            this.loginBtnState = false;
            this.loginSpinnerState = true;
            console.log(err1.error);
          },
        });

        // this.router.navigate(['/candidat/setcv']);
      },
      (err) => {
        this.loginBtnState = false;
        this.loginSpinnerState = true;
        this.errorMessage.message = "Email ou mot de passe incorrect";
        this.errorMessage.status = 403;
        console.log(err.error);
      }
    );
  }

  onRegisterCandidat() {
    this.registerSpinnerState = false;
    // this.router.navigate(['/candidat/setcv']);
    let registered = this.registerform.value;
    if (registered.password != registered.password1) {
      this.messageconfirmpassword = "Les mots de passe sont diferent ";
      this.registerSpinnerState = true;
    } else {
      this.messageconfirmpassword = "";

      this.candidatservice.add_candidat(this.registerform.value).subscribe(
        (response) => {
          // alert("Succès enregitrement");
          let user = {
            identifiant: this.registerform.value.email,
            password: this.registerform.value.password,
          };

          this.candidatservice.connexion_candidat(user).subscribe(
            (response1) => {
              let jwt = response1.headers.get("Authorization");
              this.candidatservice.saveToken(jwt);

              this.entrepriseServices.find_user().subscribe({
                next: (response2) => {
                  this.registerSpinnerState = true;
                  this.user = response2;
                  // console.log(response1);
                  // localStorage.setItem("logo", this.users.entrepriseAccount.logo);
                  localStorage.setItem(
                    "username",
                    this.user.candidat.nom + " " + this.user.candidat.prenom
                  );
                  // console.log(localStorage.getItem("username"))
                  localStorage.setItem(
                    "candidat_oid",
                    String(btoa(String(this.user.candidat.oid)))
                  );
                  // this.router.navigate(["/candidat/setcv"]);
                  this.closeModal.nativeElement.click();
                },
                error: (err1) => {
                  this.registerSpinnerState = true;
                  console.log(err1.error);
                },
              });

              // this.router.navigate(['/candidat/setcv']);
            },
            (err) => {
              this.registerSpinnerState = true;
              console.log(err.error.massage);
            }
          );

          //this.registerserve.userconnectservice = response
          // this.router.navigate(['/candidat/setcv']);
          // this.router.navigate(["/candidat/login"]);
          //console.log(response)
        },
        (err) => {
          this.registerSpinnerState = true;
          // this.messageconfirmpassword = err;
          this.errorMessageRegister.message =
            "Un utilisateur existe déja avec cet email";
          this.errorMessageRegister.status = err.error.status;
          console.log(err.error);
        }
      );
    }
  }

  forgotPasswordNavigate() {
    this.closeModal.nativeElement.click();
    this.router.navigate(["/candidat/forgot-password"]);
  }

  onVerifInitPasse() {
    this.messageconfirmpassword = "";
  }
}
