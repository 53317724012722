import { Injectable } from "@angular/core";
import { myConst } from "../../../models/entreprise/settings";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { UsersService } from "../../userServices/users.service";

@Injectable({
  providedIn: "root",
})
export class OffersService {
  host = myConst.url;
  private customHttpClient: HttpClient;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    backend: HttpBackend,
    private usersService: UsersService
  ) {
    this.customHttpClient = new HttpClient(backend);
  }

  create_offre(offreEmploi: any) {
    return this.http.post(myConst.url.concat("/create_offre"), offreEmploi);
  }

  create_withoutImage(offreEmploi: any) {
    return this.http.post(
      myConst.url.concat("/create_withoutImage"),
      offreEmploi
    );
  }

  update_offre(offreEmploi: any) {
    return this.http.put(myConst.url.concat("/update_offre"), offreEmploi);
  }

  update_withoutImage(offreEmploi: any) {
    return this.http.put(
      myConst.url.concat("/update_withoutImage"),
      offreEmploi
    );
  }

  find_offre(oid_offre) {
    return this.customHttpClient.get(
      myConst.url.concat("/find_offre/" + oid_offre)
    );
  }

  list_offre() {
    return this.customHttpClient.get(myConst.url.concat("/list_offre"));
  }

  imageOffre(oid_offre) {
    return this.http.get(myConst.url.concat("/imageOffre/" + oid_offre));
  }

  getImageOffre(filename: string): any {
    return this.http
      .post(myConst.url.concat("/getImageOffre"), filename, {
        responseType: "blob",
        observe: "response",
      })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: "image/jpeg" });
        })
      );
  }

  defaultOffreImage() {
    return "assets/img/téléchargement (1).jpg";
  }

  list_similary_offre(paremSimilaryOffer, page: number) {
    return this.customHttpClient.post(
      myConst.url.concat("/list_similary_offre?page=" + page),
      paremSimilaryOffer
    );
  }

  list_my_offre(entreprise, page: number) {
    return this.http.get(
      myConst.url.concat("/list_my_offre/" + entreprise + "?page=" + page)
    );
  }

  supprimer_offre(offreEmploi: any) {
    return this.http.put(myConst.url.concat("/supprimer_offre"), offreEmploi);
  }

  postuler_offre(postulerOffre) {
    return this.http.post(myConst.url.concat("/postuler_offre"), postulerOffre);
  }

  postulerOffre(offreEmplois) {
    if (this.usersService.isLoggedInCdt()) {
      // console.log(localStorage.getItem("token"));
      let postulerOffre = {
        offreEmplois: { oid: offreEmplois.oid },
        candidat: { oid: Number(atob(localStorage.getItem("candidat_oid"))) },
      };
      this.postuler_offre(postulerOffre).subscribe({
        next: (response) => {
          this.toastr.success(response["message"], "Success");
        },
        error: (err) => {
          console.log(err.error);
        },
      });
    } else {
      return;
    }
  }

  filterOffres(paremOffreFilter) {
    return this.customHttpClient.post(
      myConst.url.concat("/filterOffres"),
      paremOffreFilter
    );
  }

  listOffrePagination(page) {
    return this.customHttpClient.get(
      myConst.url.concat("/listOffrePagination?page=" + page)
    );
  }
}
