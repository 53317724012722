import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { RegisterService } from "src/app/services/candidat/register.service";
import { LoginComponent } from "../login/login.component";
import { SettingsService } from "src/app/services/entrepriseServices/settings/settings.service";
import * as $ from "jquery";
import { UsersService } from "src/app/services/userServices/users.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-setcv",
  templateUrl: "./setcv.component.html",
  styleUrls: ["./setcv.component.css"],
})
export class SetcvComponent implements OnInit {
  candidat;
  competence;
  scolarite;
  experiencepro;
  langue;
  loisir;
  listePays;
  identiteForm: FormGroup;
  competenceForm: FormGroup;
  scolariteForm: FormGroup;
  experienceForm: FormGroup;
  langueForm: FormGroup;
  loisirForm: FormGroup;
  uploadCvForm: FormGroup;
  cssvalidator;
  validatorupload: string;
  selectedFileCv: File;
  cvFileSlected: File;
  file_statut: boolean = true;
  uploadSpinnerState: boolean = false;
  deleteCvSpinnerState: boolean = false;
  getCvSpinnerState: boolean = false;
  listActivitySectorCdtList;
  desabledBtnCompetence: boolean = false;
  desabledBtnUserInfo: boolean = false;
  desabledBtnUserSchool: boolean = false;
  desabledBtnUserExpPro: boolean = false;
  desabledBtnUserLangue: boolean = false;
  desabledBtnUserLoisir: boolean = false;
  desabledBtnUserIdentifiant: boolean = false;
  desabledBtnUserPwd: boolean = false;
  datePipe = new DatePipe("en-US");
  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  activitySectorsList;
  updtUsernameForm: FormGroup;
  updtPwdForm: FormGroup;
  myCv = null;

  listlangue = [
    "Français",
    "Anglais",
    "Chinoi",
    "Espagnol",
    "Allemand",
    "Arabe",
    "Portugais",
    "Russe",
    "Japonais",
    "Coréen",
    "Italien",
    "Malgache",
    "Grec",
    "Hindi",
  ];
  updtIdentifiantResponse = {
    message: null,
    status: null,
  };

  // activitySector = [
  //   "Agroalimentaire",
  //   "Banque / Assurance / Finance",
  //   "Bois / Papier / Carton / Imprimerie",
  //   "BTP / Matériaux de construction",
  //   "Chimie / Parachimie",
  //   "Commerce / Négoce / Distribution",
  //   "Édition / Communication / Multimédia",
  //   "Électronique / Électricité",
  //   "Études et conseils",
  //   "Industrie pharmaceutique / Medical",
  //   "Informatique / Télécoms",
  //   "Machines et équipements / Automobile",
  //   "Métallurgie / Travail du métal",
  //   "Plastique / Caoutchouc",
  //   "Services aux entreprises",
  //   "Textile / Habillement / Chaussure / Coiffure",
  //   "Transports / Logistique",
  //   "Envenementiel",
  //   "Art et culture",
  // ];

  listniveaulangue = ["Scolaire", "Intermedaire", "Soutenu"];
  listniveau = [
    "Primaire",
    "Secondaire",
    "DUT ou Equivalent",
    "Licence ou Equivalent",
    "Master 1 ou Equivalent",
    "Master 2 ou Equivalent",
    "Doctorat",
  ];

  expYears = ["0 à 2 ans", "3 à 5 ans", "6 à 9 ans", "10 ans et plus"];

  listdomaineformation = [
    "Scientifique",
    "Technologique",
    "Industriel",
    "Comptabilite et finance",
    "Management",
    "Commercial",
    "Environement",
    "Gestion",
    "Communication",
    "Litterature",
  ];

  langueArray = [];

  constructor(
    private fb: FormBuilder,
    private registerservice: RegisterService,
    private router: Router,
    private toastr: ToastrService,
    private settings: SettingsService,
    private usersServices: UsersService
  ) {}

  ngOnInit() {
    this.candidatconnect();
    this.list_ActivitySector();

    // console.log(atob(localStorage.getItem("userId")));
    //this.candidat = this.registerservice.candidatserv
    this.updtUsernameForm = this.fb.group({
      oid: new FormControl(-1, Validators.required),
      entrepriseId: new FormControl("", Validators.required),
    });

    this.updtPwdForm = this.fb.group({
      oid: new FormControl(-1, Validators.required),
      old_password: new FormControl("", Validators.required),
      new_password: new FormControl("", [Validators.required]),
    });

    // this.identiteForm = this.fb.group({
    //   oid: "",
    //   civilite: "",
    //   nom: ["", Validators.required],
    //   prenom: ["", Validators.required],
    //   email: ["", Validators.required],
    //   datenaissance: new FormControl(),
    //   lieumaissance: ["", Validators.required],
    //   pays: ["", Validators.required],
    //   ville: ["", Validators.required],
    //   telephone1: ["", Validators.required],
    //   telephone2: [""],
    //   // nbreanneeexp: new FormControl("", [
    //   //   Validators.required,
    //   //   Validators.min(0),
    //   //   Validators.pattern(/^\d+$/)
    //   // ]),
    //   nbreanneeexp: new FormControl("", [Validators.required]),
    //   activitySectors: ["", Validators.required],
    //   fonction: ["", Validators.required],
    //   objectif: [""],
    // });
    // this.listPays();

    // this.competenceForm = this.fb.group({
    //   oid: "",
    //   domaine: ["", Validators.required],
    //   detail: ["", Validators.required],
    // });

    this.identiteForm = this.fb.group({
      oid: "",
      civilite: "",
      nom: ["", Validators.required],
      prenom: ["", Validators.required],
      email: ["", Validators.required],
      datenaissance: new FormControl(),
      lieumaissance: new FormControl(),
      pays: new FormControl(),
      ville: new FormControl(),
      telephone1: new FormControl(),
      telephone2: new FormControl(),
      // nbreanneeexp: new FormControl("", [
      //   Validators.required,
      //   Validators.min(0),
      //   Validators.pattern(/^\d+$/)
      // ]),
      nbreanneeexp: new FormControl(),
      activitySectors: new FormControl(),
      fonction: new FormControl(),
      objectif: new FormControl(),
    });
    this.listPays();

    this.competenceForm = this.fb.group({
      oid: "",
      domaine: ["", Validators.required],
      detail: ["", Validators.required],
    });

    this.scolariteForm = this.fb.group({
      oid: "",
      datedebut: ["", Validators.required],
      datefin: ["", Validators.required],
      domaine: ["", Validators.required],
      specialite: ["", Validators.required],
      niveau: ["", Validators.required],
      pays: ["", Validators.required],
      ville: ["", Validators.required],
      etablissement: ["", Validators.required],
    });

    this.experienceForm = this.fb.group({
      oid: [""],
      datedebut: ["", Validators.required],
      datefin: ["", Validators.required],
      // duree: ["", Validators.required],
      entreprise: ["", Validators.required],
      poste: ["", Validators.required],
      tache: new FormControl(),
      pays: ["", Validators.required],
      ville: ["", Validators.required],
    });

    this.loisirForm = this.fb.group({
      oid: "",
      loisir: ["", Validators.required],
    });

    this.uploadCvForm = this.fb.group({
      chemincv: ["", Validators.required],
      cv: [""],
    });
  }

  getToday(): string {
    return new Date().toISOString().split("T")[0];
  }

  get entrepriseId_register() {
    return this.updtUsernameForm.get("entrepriseId");
  }

  get old_password_register() {
    return this.updtPwdForm.get("old_password");
  }
  get new_password_register() {
    return this.updtPwdForm.get("new_password");
  }

  list_ActivitySector() {
    this.settings.list_ActivitySector().subscribe({
      next: (response) => {
        this.activitySectorsList = response;
        // console.log(this.activitySectorsList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  onEditIdentiteCandidat() {
    // console.log(this.identiteForm.value);
    this.desabledBtnUserInfo = true;
    this.registerservice.edit_candidat(this.identiteForm.value).subscribe(
      (response) => {
        localStorage.setItem("username", this.identiteForm.get("nom").value);
        // alert("Succès modification");
        this.desabledBtnUserInfo = false;
        this.toastr.success("Enregistrer", "Success");
      },
      (err) => {
        this.desabledBtnUserInfo = false;
        console.log(err);
      }
    );
  }

  candidatconnect() {
    this.registerservice.load_candidat().subscribe((data) => {
      this.candidat = data;
      // console.log(this.candidat);

      this.competence = this.candidat.competence;
      this.scolarite = this.candidat.scolarite;
      this.experiencepro = this.candidat.experience;
      this.langue = this.candidat.langue;
      this.loisir = this.candidat.loisir;

      this.myCv = this.candidat.cv;

      this.identiteForm.patchValue({
        oid: this.candidat.oid,
        civilite: this.candidat.civilite,
        nom: this.candidat.nom,
        prenom: this.candidat.prenom,
        email: this.candidat.email,
        datenaissance: this.candidat.datenaissance,
        lieumaissance: this.candidat.lieumaissance,
        pays: this.candidat.pays,
        ville: this.candidat.ville,
        telephone1: this.candidat.telephone1,
        telephone2: this.candidat.telephone2,
        nbreanneeexp: this.candidat.nbreanneeexp,
        objectif: this.candidat.objectif,
        fonction: this.candidat.fonction,
        activitySectors: this.candidat.activitySectors,
      });
      this.updtUsernameForm.patchValue({
        entrepriseId: this.candidat.email,
      });

      // Loisir

      this.loisirForm.patchValue({
        oid: this.loisir[0].oid,
        loisir: this.loisir[0].loisir,
      });

      /// Langue

      let langue = this.langue;
      for (var i = 0; i < langue.length; i++) {
        this.langueArray.push({
          oid: langue[i].oid,
          langue: langue[i].langue,
          parle: langue[i].parle,
          lue: langue[i].lue,
          ecrit: langue[i].ecrit,
        });
      }

      this.uploadCvForm.patchValue({
        cv: this.candidat.cv,
      });

      //this.registerservice.candidatserv=this.candidat
    }),
      (err) => {
        console.log(err);
      };
  }

  ///////////////////////// Competence  //////////////////////////////////////////////////////

  onRazComepetence() {
    this.competenceForm.setValue({
      oid: "",
      domaine: "",
      detail: "",
    });
  }

  onEditComepetence(index) {
    this.competenceForm.setValue({
      oid: this.competence[index].oid,
      domaine: this.competence[index].domaine,
      detail: this.competence[index].detail,
    });
  }

  onSaveCompetence() {
    var oidc = this.competenceForm.get("oid").value;
    this.desabledBtnCompetence = true;

    if (oidc == null || oidc == "") {
      let competence = {
        domaine: this.competenceForm.get("domaine").value,
        detail: this.competenceForm.get("detail").value,
        candidat: {
          oid: this.identiteForm.get("oid").value,
        },
      };

      this.registerservice.add_competence(competence).subscribe(
        (response) => {
          this.ngOnInit();
          // alert("Succès enregistrement");
          this.desabledBtnCompetence = false;
          this.toastr.success("Enregistrer", "Success");
        },
        (err) => {
          this.desabledBtnCompetence = false;
          console.log(err);
        }
      );
    } else {
      let competence = {
        oid: this.competenceForm.get("oid").value,
        domaine: this.competenceForm.get("domaine").value,
        detail: this.competenceForm.get("detail").value,
      };

      this.registerservice.edit_competence(competence).subscribe(
        (response) => {
          // alert("Succès modification");
          this.desabledBtnCompetence = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnCompetence = false;
          console.log(err);
        }
      );
    }
  }

  onDellCompetence(index: number) {
    let oidc = this.competence[index];

    this.registerservice.dell_competence(this.competence[index]).subscribe(
      (response) => {
        this.toastr.success("Supprimer", "Success");
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ///////////////////////// Scolarite  //////////////////////////////////////////////////////

  onRazScolarite() {
    this.scolariteForm.setValue({
      oid: "",
      datedebut: "",
      datefin: "",
      domaine: "",
      specialite: "",
      niveau: "",
      pays: "",
      ville: "",
      etablissement: "",
    });
  }

  onSaveScolarite() {
    //console.log(this.scolariteForm.value)
    let oids = this.scolariteForm.get("oid").value;
    this.desabledBtnUserSchool = true;

    if (oids == null || oids == "") {
      let scolarite1 = this.scolariteForm.value;
      let candidat = {
        candidat: {
          oid: this.identiteForm.get("oid").value,
        },
      };

      let scolarite = Object.assign(scolarite1, candidat);
      //console.log(scolarite)
      this.registerservice.add_scolarite(scolarite).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserSchool = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserSchool = false;
          console.log(err);
        }
      );
    } else {
      let scolarite = this.scolariteForm.value;
      //console.log(scolarite)
      this.registerservice.edit_scolarite(scolarite).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserSchool = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserSchool = false;
          console.log(err);
        }
      );
    }
  }

  onEditScolarite(index) {
    this.scolariteForm.setValue({
      oid: this.scolarite[index].oid,
      datedebut: this.scolarite[index].datedebut,
      datefin: this.scolarite[index].datefin,
      domaine: this.scolarite[index].domaine,
      specialite: this.scolarite[index].specialite,
      niveau: this.scolarite[index].niveau,
      pays: this.scolarite[index].pays,
      ville: this.scolarite[index].ville,
    });
  }

  onDellScolarite(index: number) {
    let oidc = this.scolarite[index];

    this.registerservice.dell_scolarite(this.scolarite[index]).subscribe(
      (response) => {
        // alert("Succès suppression");
        this.toastr.success("Supprimer", "Success");
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //////////////////////// Experience //////////////////////////////

  onRazExperience() {
    this.experienceForm.setValue({
      oid: "",
      datedebut: "",
      datefin: "",
      // duree: "",
      entreprise: "",
      poste: "",
      tache: "",
      pays: "",
      ville: "",
    });
  }

  onSaveExperience() {
    //console.log(this.experienceForm.value)

    let oide = this.experienceForm.get("oid").value;
    this.desabledBtnUserExpPro = true;

    if (oide == null || oide == "") {
      let experience1 = this.experienceForm.value;
      let candidat = {
        candidat: {
          oid: this.identiteForm.get("oid").value,
        },
      };

      let experience = Object.assign(experience1, candidat);
      //console.log(scolarite)
      this.registerservice.add_experience(experience).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserExpPro = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserExpPro = false;
          console.log(err);
        }
      );
    } else {
      let experience = this.experienceForm.value;
      //console.log(scolarite)
      this.registerservice.edit_experience(experience).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserExpPro = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserExpPro = false;
          console.log(err);
        }
      );
    }
  }

  onEditExperience(index) {
    // console.log(this.experiencepro[index])
    this.experienceForm.setValue({
      oid: this.experiencepro[index].oid,
      datedebut: this.experiencepro[index].datedebut,
      datefin: this.experiencepro[index].datefin,
      // duree: this.experiencepro[index].duree,
      entreprise: this.experiencepro[index].entreprise,
      poste: this.experiencepro[index].poste,
      tache: this.experiencepro[index].tache,
      pays: this.experiencepro[index].pays,
      ville: this.experiencepro[index].ville,
    });
  }

  onDellExperience(index: number) {
    let oidc = this.experiencepro[index];

    this.registerservice.dell_experience(this.experiencepro[index]).subscribe(
      (response) => {
        // alert("Succès suppression");
        this.toastr.success("Supprimer", "Success");
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ////////////////////// Loisir ///////////////////////////////////////////////

  onSaveLoisir() {
    //console.log(this.experienceForm.value)

    let oide = this.loisirForm.get("oid").value;
    this.desabledBtnUserLoisir = true;
    this.toastr.success("Enregistrer", "Success");

    if (oide == null || oide == "") {
      let loisir1 = this.loisirForm.value;
      let candidat = {
        candidat: {
          oid: this.identiteForm.get("oid").value,
        },
      };

      let loisir = Object.assign(loisir1, candidat);
      //console.log(scolarite)
      this.registerservice.add_loisir(loisir).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserLoisir = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserLoisir = false;
          this.toastr.error(err.error.message, "Error");
          console.log(err);
        }
      );
    } else {
      let loisir = this.loisirForm.value;
      //console.log(scolarite)
      this.registerservice.edit_loisir(loisir).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserLoisir = false;
          this.toastr.success("Enregistrer", "Success");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserLoisir = false;
          console.log(err);
        }
      );
    }
  }

  ////////////////////////////// Langues ////////////////////////////////////////////

  onAddLangue() {
    this.langueArray.push({
      oid: "",
      langue: ["", Validators.required],
      parle: "",
      lue: "",
      ecrit: "",
    });
    // console.log('New row added successfully', 'New Row');
  }

  onSaveLangue(index) {
    let oide = this.langueArray[index].oid;
    this.desabledBtnUserLangue = true;

    if (oide == null || oide == "") {
      let langue1 = this.langueArray[index];
      let candidat = {
        candidat: {
          oid: this.identiteForm.get("oid").value,
        },
      };

      let langue = Object.assign(langue1, candidat);
      //console.log(scolarite)
      this.registerservice.add_langue(langue).subscribe(
        (response) => {
          this.langueArray = [];
          this.desabledBtnUserLangue = false;
          this.toastr.success("Enregistrer", "Success");
          // alert("Succès enregistrement");
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserLangue = false;
          console.log(err);
        }
      );
    } else {
      let langue = this.langueArray[index];
      //console.log(scolarite)
      this.registerservice.edit_langue(langue).subscribe(
        (response) => {
          // alert("Succès enregistrement");
          this.desabledBtnUserLangue = false;
          this.toastr.success("Enregistrer", "Success");
          this.langueArray = [];
          this.ngOnInit();
        },
        (err) => {
          this.desabledBtnUserLangue = false;
          console.log(err);
        }
      );
    }
  }

  onDellLangue(index) {
    this.registerservice.dell_langue(this.langueArray[index]).subscribe(
      (response) => {
        // alert("Succès suppression");
        this.toastr.success("Spprimer", "Success");
        this.langueArray = [];
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
      }
    );
    //this.langueArray.splice(index, 1);
  }

  uploadCv() {
    this.uploadSpinnerState = true;
    const formdata = new FormData();
    formdata.append("file", this.selectedFileCv);
    formdata.append("candidat", this.registerservice.candidat);
    this.registerservice.uploadFileCv(formdata).subscribe(
      (response) => {
        this.uploadSpinnerState = false;
        // console.log(response.body)
        if (response["status"] == true) {
          // this.ngOnInit();
          this.uploadCvForm.reset();
          this.myCv = response["message"];
          // console.log(this.myCv)
          // alert("Cv successfully uploaded.");
          this.toastr.success("Votre CV a été charger avec success", "Success");
        } else {
          this.toastr.success(response["message"], "Success");
        }
      },
      (err) => {
        this.uploadSpinnerState = false;
        console.log(err);
      }
    );
  }

  deleteCv() {
    this.deleteCvSpinnerState = true;

    if (confirm("Voulez vous vraiment supprimer votre CV?")) {
      this.registerservice.deleteFileCv(this.candidat.oid).subscribe(
        (response) => {
          if (response["status"] == true) {
            this.deleteCvSpinnerState = false;
            // this.ngOnInit();
            // this.uploadCvForm.reset();
            // alert("Cv successfully uploaded.");
            this.myCv = null;
            this.toastr.success(response["message"], "Success");
          } else {
            this.deleteCvSpinnerState = false;
            this.toastr.error(response["message"], "Error");
          }
        },
        (err) => {
          this.deleteCvSpinnerState = false;
          console.log(err);
        }
      );
    } else {
      this.deleteCvSpinnerState = false;
      return;
    }
  }

  readFile(fileEvent: any) {
    const file = fileEvent.target.files[0];
    this.selectedFileCv = fileEvent.target.files[0];
    //2.6Mo
    if (
      file.type == "application/pdf" ||
      file.type == "image/jpeg" ||
      file.type == "image/png"
    ) {
      if (file.size <= 2676245) {
        this.validatorupload = "";
      } else {
        this.validatorupload = "Taille maximal est de 2.5Mo";
      }
    } else {
      this.validatorupload = "Type de fichier accepté : pdf/jpeg/png";
    }
  }

  listPays() {
    this.registerservice.list_countrie().subscribe(
      (data) => {
        this.listePays = data;
      },
      (err) => {
        this.toastr.error(err.error.message, "Error");
        console.log(err);
      }
    );
  }

  getCvCandidat() {
    this.getCvSpinnerState = true;
    this.registerservice.getCvCandidat(this.candidat.oid).subscribe((res) => {
      this.getCvSpinnerState = false;
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, "_blank");
    });
  }

  // uploadCdtCv() {
  //   let cdtFormData = new FormData();
  //   let candidat = {
  //     oid:
  //   }

  // }

  // onFileChangedLogo(event) {
  //   if (event.target.files && event.target.files[0]) {
  //     let file = event.target.files[0];
  //     console.log(file);
  //     if (file.type == "image/jpeg") {
  //       this.cvFileSlected = event.target.files[0];
  //       console.log("correct");
  //       this.file_statut = true;
  //     } else {
  //       this.file_statut = false;
  //       //call validation
  //       this.registerForm.get("logo").reset();
  //       this.registerForm.controls["logo"].setValidators([Validators.required]);
  //       this.registerForm.get("logo").updateValueAndValidity();
  //     }
  //   }
  // }

  update_username() {
    if (this.updtUsernameForm.valid) {
      this.desabledBtnUserIdentifiant = true;
      let users = {
        oid: Number(atob(localStorage.getItem("userId"))),
        identifiant: this.updtUsernameForm.get("entrepriseId").value,
        candidat: { oid: this.identiteForm.get("oid").value },
      };
      // console.log(this.updtUsernameForm.value);
      this.usersServices.updateUsernameCdt(users).subscribe({
        next: (response) => {
          this.desabledBtnUserIdentifiant = false;
          this.updtIdentifiantResponse.message = response["message"];
          this.updtIdentifiantResponse.status = response["status"];
          if (this.updtIdentifiantResponse.status == false) {
            $(".identifiant-div").show();
            setTimeout(function () {
              $(".identifiant-div").hide();
            }, 5000);
          } else {
            $(".identifiant-div").show();
            setTimeout(function () {
              $(".identifiant-div").hide();
            }, 5000);
            this.toastr.success("Enregistrer", "Success");
            this.usersServices.logoutCdt();
          }
        },
        error: (err) => {
          this.desabledBtnUserIdentifiant = false;
          console.log(err.error);
        },
      });
    } else {
      return;
    }
  }

  update_password() {
    if (this.updtPwdForm.valid) {
      this.desabledBtnUserPwd = true;
      let users = {
        oid: atob(localStorage.getItem("userId")),
        password: this.updtPwdForm.get("old_password").value,
        re_password: this.updtPwdForm.get("new_password").value,
        candidat: { oid: this.identiteForm.get("oid").value },
      };
      this.usersServices.change_password(users).subscribe({
        next: (response) => {
          this.desabledBtnUserPwd = false;
          this.updtIdentifiantResponse.message = response["message"];
          this.updtIdentifiantResponse.status = response["status"];
          if (this.updtIdentifiantResponse.status == false) {
            $(".identifiant-div").show();
            setTimeout(function () {
              $(".identifiant-div").hide();
            }, 5000);
          } else {
            $(".identifiant-div").show();
            setTimeout(function () {
              $(".identifiant-div").hide();
            }, 5000);
            this.toastr.success("Enregistrer", "Success");
          }
        },
        error: (err) => {
          this.desabledBtnUserPwd = false;
          console.log(err.error);
        },
      });
    } else {
      return;
    }
  }
}
