import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { PositioningPersonsComponent } from "./components/servicesComponents/positioning-persons/positioning-persons.component";
import { ManagementRhComponent } from "./components/servicesComponents/management-rh/management-rh.component";
import { AuditRhComponent } from "./components/servicesComponents/audit-rh/audit-rh.component";
import { FiscalityAdministrativeComponent } from "./components/servicesComponents/fiscality-administrative/fiscality-administrative.component";
import { TrainingComponent } from "./components/servicesComponents/training/training.component";
import { MaintenanceComponent } from "./components/errorsComponents/maintenance/maintenance.component";
import { RegisterComponent } from "./components/entrepriseComponents/register/register.component";
import {
  AuthService,
  AuthServiceCandidtat,
} from "./services/guards/auth/auth.service";
import { LoginComponent } from "./components/candidat/login/login.component";
import { ForgotPasswordComponent } from "./components/entrepriseComponents/forgot-password/forgot-password.component";
import { CreateOffreComponent } from "./components/entrepriseComponents/create-offre/create-offre.component";
import { FindProfilesComponent } from "./components/candidat/find-profiles/find-profiles.component";
import { ForbiddenErrorsComponent } from "./components/errorsComponents/forbidden-errors/forbidden-errors.component";
import { NotFondErrorsComponent } from "./components/errorsComponents/not-fond-errors/not-fond-errors.component";
import { MyAccountComponent } from "./components/entrepriseComponents/my-account/my-account.component";
import { UpdateMyAccountComponent } from "./components/entrepriseComponents/update-my-account/update-my-account.component";
import { ProfileDetailComponent } from "./components/candidat/profile-detail/profile-detail.component";
import { ListOfferComponent } from "./components/entrepriseComponents/list-offer/list-offer.component";
import { DetailOfferComponent } from "./components/entrepriseComponents/detail-offer/detail-offer.component";
import { MyPublicationsComponent } from "./components/entrepriseComponents/my-publications/my-publications.component";
import { OurMissionsComponent } from "./components/our-missions/our-missions.component";
import { OurPoliticComponent } from "./components/our-politic/our-politic.component";
import { OurVisionsComponent } from "./components/our-visions/our-visions.component";
import { OurValuesComponent } from "./components/our-values/our-values.component";
import { UpdateMyPublicationsComponent } from "./components/entrepriseComponents/update-my-publications/update-my-publications.component";
import { AskProfileComponent } from "./components/entrepriseComponents/ask-profile/ask-profile.component";
import { MesProfilsComponent } from "./components/entrepriseComponents/mes-profils/mes-profils.component";
import { UpdateAskProfileComponent } from "./components/entrepriseComponents/update-ask-profile/update-ask-profile.component";
import { SetcvComponent } from "./components/candidat/setcv/setcv.component";
import { LoginEntrepriseComponent } from "./components/entrepriseComponents/login-entreprise/login-entreprise.component";
import { RegistercandidatComponent } from "./components/candidat/registercandidat/registercandidat.component";
import { PostuleroffreComponent } from "./components/candidat/postuleroffre/postuleroffre.component";
import { SpontCandidatureComponent } from "./components/candidat/spont-candidature/spont-candidature.component";
import { VerifyEntrepriseAccountComponent } from "./components/entrepriseComponents/verify-entreprise-account/verify-entreprise-account.component";
import { ForgotPasswordCandidatComponent } from "./components/candidat/forgot-password-candidat/forgot-password-candidat.component";

const appRoutes: Routes = [
  // {
  //   path: "",
  //   component: DashboardComponent,
  //   children: [
  //     {
  //       path: "setting",
  //       component: SettingsComponent,
  //       outlet: "settingOutlet",
  //     },
  //   ],
  // },

  //Routes de la partie statique
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "qui-sommes-nous",
    component: AboutUsComponent,
  },
  {
    path: "nous-contacter",
    component: ContactUsComponent,
  },
  {
    path: "nos-services/placement-du-personnel",
    component: PositioningPersonsComponent,
  },
  {
    path: "nos-services/management-des-rh",
    component: ManagementRhComponent,
  },
  {
    path: "nos-services/audit-des-rh",
    component: AuditRhComponent,
  },
  {
    path: "nos-services/facilite-administrative",
    component: FiscalityAdministrativeComponent,
  },
  {
    path: "nos-services/fomration",
    component: TrainingComponent,
  },
  {
    path: "qui-sommes-nous/nos-missions",
    component: OurMissionsComponent,
  },
  {
    path: "qui-sommes-nous/notre-vision",
    component: OurVisionsComponent,
  },
  {
    path: "qui-sommes-nous/notre-politique",
    component: OurPoliticComponent,
  },
  {
    path: "qui-sommes-nous/nos-valeurs",
    component: OurValuesComponent,
  },

  //Errors routes

  {
    path: "errors/setting",
    component: MaintenanceComponent,
  },

  {
    path: "errors/forbidden",
    component: ForbiddenErrorsComponent,
  },

  {
    path: "errors/not-fond",
    component: NotFondErrorsComponent,
  },

  //Routes de la partie entrepries
  {
    path: "entreprise/register",
    component: RegisterComponent,
  },
  {
    path: "entreprise/register/verify-entreprise-account",
    component: VerifyEntrepriseAccountComponent,
  },
  {
    path: "entreprise/login",
    component: LoginEntrepriseComponent,
  },
  {
    path: "entreprise/forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "entreprise/create-offer",
    canActivate: [AuthService],
    component: CreateOffreComponent,
  },
  {
    path: "entreprise/list-offers",
    // canActivate: [AuthServiceCandidtat],
    component: ListOfferComponent,
  },
  {
    path: "entreprise/detail-offers/:offre_oid",
    // canActivate: [AuthServiceCandidtat],
    component: DetailOfferComponent,
  },
  {
    path: "entreprise/my-publications",
    canActivate: [AuthService],
    component: MyPublicationsComponent,
  },
  {
    path: "entreprise/edit-offers/:offre_oid",
    // canActivate: [AuthService],
    component: UpdateMyPublicationsComponent,
  },
  {
    path: "entreprise/ask-profile",
    canActivate: [AuthService],
    component: AskProfileComponent,
  },
  {
    path: "entreprise/my-profile",
    canActivate: [AuthService],
    component: MesProfilsComponent,
  },
  {
    path: "entreprise/edit-ask-profile/:askProfile_oid",
    canActivate: [AuthService],
    component: UpdateAskProfileComponent,
  },
  {
    path: "entreprise/my-account",
    canActivate: [AuthService],
    component: MyAccountComponent,
  },
  {
    path: "entreprise/edit-my-account",
    canActivate: [AuthService],
    component: UpdateMyAccountComponent,
  },

  //Candidate route
  {
    path: "candidat/login",
    component: LoginComponent,
  },

  {
    path: "candidate/find-profile",
    // canActivate: [AuthService],
    component: FindProfilesComponent,
  },

  {
    path: "candidat/register-canditate",
    component: RegistercandidatComponent,
  },

  {
    path: "candidat/setcv",
    canActivate: [AuthServiceCandidtat],
    component: SetcvComponent,
  },

  {
    path: "candidat/my-candidacy",
    canActivate: [AuthServiceCandidtat],
    component: PostuleroffreComponent,
  },
  {
    path: "candidat/forgot-password",
    component: ForgotPasswordCandidatComponent,
    // canActivate: [AuthService],
  },

  {
    path: "candidate/profile-detail/:idcandidat",
    // canActivate: [AuthService],
    component: ProfileDetailComponent,
  },
  // {
  //   path: "candidat/login",
  //   component: LoginComponent,
  // },

  {
    path: "candidat/spontaned-candidature",
    // canActivate: [AuthService],
    component: SpontCandidatureComponent,
  },

  {
    path: "candidat/apply-offer/:oidoffreemploi",
    component: PostuleroffreComponent,
  },

  //Dashboard route
  // {
  //   path: "admin",
  //   component: DashboardComponent,
  // },
  // {
  //   path: "admin/candidat-management",
  //   component: CandidatManagementComponent,
  // },

  // {
  //   path: "admin",
  //   component: DashboardComponent,
  //   children: [
  //     {
  //       path: "offre-management",
  //       component: OffreManagementComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "detail-offre-admin/:oidoffreemploi",
  //       component: DetailOffreAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "detail-candidat-admin/:oidcandidat",
  //       component: DetailCandidatAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "all-candidat-admin",
  //       component: AllCandidatAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "free-candidat-admin",
  //       component: FreeCandidatAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "occuped-candidat-admin",
  //       component: OccupedCandidatAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "entreprise-management",
  //       component: EntrepriseManagementComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "detail-offre-entreprise-admin/:oidEntreprise",
  //       component: DetailOffreEntrepriseAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "ask-profile-entreprise-admin/:oidEntreprise",
  //       component: AskProfileEntrepriseAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //     {
  //       path: "users-management-admin",
  //       component: UserManagerAdminComponent,
  //       outlet: "offreAdmin",
  //       canActivate: [AuthService],
  //     },
  //   ],
  // },
  // {
  //   path: "test",
  //   component: DetailOffreAdminComponent,
  //   outlet: "offreAdmin",
  // },
  // {
  //   path: "admin/detail-offer-admin/:oidoffreemploi",
  //   component: DetailOffreAdminComponent,
  //   outlet: "detailOffreAdmin",
  // },
  // {
  //   path: "admin",
  //   loadChildren: () =>
  //     import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
//export const appRoutingModule = RouterModule.forRoot(routes);
//export const RoutingComponent = [SettingsComponent,LoginComponent];
