import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.css']
})
export class LanguageModalComponent {
  languages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' },
    { code: 'ar', label: 'العربية' },
    { code: 'zh', label: '中文' },
    { code: 'es', label: 'Español'}
  ];

  selectedLanguage = 'fr'; // Default selection

  constructor(public dialogRef: MatDialogRef<LanguageModalComponent>) { }

  selectLanguage() {
    this.dialogRef.close(this.selectedLanguage);
  }
}
