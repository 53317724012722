import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { myConst } from "../../../models/entreprise/settings";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ChopperCandidatService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  create_chopper(chopper: any) {
    return this.http.post(myConst.url.concat("/create_chopper"), chopper);
  }

  cancel_chopper(chopper: any) {
    return this.http.put(myConst.url.concat("/cancel_chopper"), chopper);
  }

  list_chopper(entrepriseAccount: any, page: number) {
    return this.http.post(
      myConst.url.concat("/list_chopper?page=" + page),
      entrepriseAccount
    );
  }

  store_chopper(index) {
    let chopper = {
      entrepriseAccount: {
        oid: Number(atob(localStorage.getItem("entreprise_oid"))),
      },
      candidat: { oid: index.oid },
    };
    // console.log(chopper);
    this.create_chopper(chopper).subscribe({
      next: (response) => {
        this.toastr.success(response["message"], "Success");
        // alert(response["message"]);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }
}
