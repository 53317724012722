import { FormGroup, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Users } from "src/app/models/entreprise/users";
import { EntrepriseService } from "src/app/services/entrepriseServices/entreprise/entreprise.service";
import { UsersService } from "src/app/services/userServices/users.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: "app-login-entreprise-modal",
  templateUrl: "./login-entreprise-modal.component.html",
  styleUrls: ["./login-entreprise-modal.component.css"],
})
export class LoginEntrepriseModalComponent implements OnInit {
  @ViewChild("closeModal")
  closeModal;
  users: Users;
  loginBtnState: boolean = false;
  loginSpinnerState: boolean = true;
  loginForm: FormGroup;
  loginError = {
    message: null,
    status: null,
  };
  // redirecturl: any = "";

  constructor(
    private formbulder: FormBuilder,
    private entrepriseServices: EntrepriseService,
    private router: Router,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.users = new Users();
    localStorage.removeItem("users_oid");
    localStorage.removeItem("email_respo");
    // this.redirecturl =
    //   this.activatedRoute.snapshot.queryParamMap.get("redirectUrl") ||
    //   "candidate/find-profile";
    // console.log(this.redirecturl)
    // this.find_user();
    // console.log(localStorage.getItem("token"));

    this.loginForm = this.formbulder.group({
      entrepriseId: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
    });
  }

  get entrepriseId_login() {
    return this.loginForm.get("entrepriseId");
  }
  get password_login() {
    return this.loginForm.get("password");
  }

  login_user() {
    const redirecturl =
      this.activatedRoute.snapshot.queryParamMap.get("redirectUrl") ||
      "candidate/find-profile";
    console.log(redirecturl);
    let jwtHelper = new JwtHelperService();
    if (this.loginForm.valid) {
      this.loginBtnState = true;
      this.loginSpinnerState = false;
      let user = {
        identifiant: this.loginForm.get("entrepriseId").value,
        role: null,
        password: this.loginForm.get("password").value,
        re_password: null,
      };
      this.entrepriseServices.login(user).subscribe({
        next: (response) => {
          this.loginBtnState = false;
          this.loginSpinnerState = true;
          let token = response.headers.get("Authorization");
          let statusCode = response.headers.get("status");
          localStorage.setItem("token", token);
          this.entrepriseServices.find_user().subscribe({
            next: (response1) => {
              this.users = response1 as Users;
              localStorage.setItem("logo", this.users.entrepriseAccount.logo);
              localStorage.setItem("name", this.users.entrepriseAccount.name);
              localStorage.setItem(
                "entreprise_oid",
                String(btoa(String(this.users.entrepriseAccount.oid)))
              );
              // this.router.navigate(["candidate/find-profile"]);
              this.closeModal.nativeElement.click();
              // this.router.navigateByUrl(redirecturl);
            },
            error: (err1) => {
              this.loginBtnState = false;
              this.loginSpinnerState = true;
              console.log(err1.error);
            },
          });
          // window.location.href = "candidate/find-profile";
        },
        error: (err) => {
          this.loginBtnState = false;
          this.loginSpinnerState = true;
          this.loginError.status = 403;
          this.loginError.message = "Identifiant ou mot de passe erroné";
          // $(".error-div").show();
          // setTimeout(function () {
          //   $(".error-div").hide();
          // }, 5000);
          console.log(err);
        },
      });
    } else {
      alert("Veillez remplir tous les champs obligatoire");
    }
  }

  find_user() {
    this.entrepriseServices.find_user().subscribe({
      next: (response1) => {
        this.users = response1 as Users;
      },
      error: (err1) => {
        console.log(err1.error);
      },
    });
  }

  registerNavigate() {
    this.router.navigate(["/entreprise/register"]);
    this.closeModal.nativeElement.click();
  }

 forgotPasswordNavigate() {
  this.closeModal.nativeElement.click();
    this.router.navigate(["/entreprise/forgot-password"]);
  }
}
