import { Component, OnInit } from "@angular/core";
import { DemandeProfil } from "../../../models/entreprise/demandeProfil";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { AskProfileService } from "../../../services/entrepriseServices/askProfile/ask-profile.service";
import { ChopperCandidatService } from "../../../services/entrepriseServices/chopperCandidat/chopper-candidat.service";
import { SettingsService } from "../../../services/settings/settings.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-mes-profils",
  templateUrl: "./mes-profils.component.html",
  styleUrls: ["./mes-profils.component.css"],
})
export class MesProfilsComponent implements OnInit {
  demandeProfil: DemandeProfil;
  demandeProfilList: DemandeProfil[];
  demandeProfilListLength: number = 0;
  datePipe = new DatePipe("en-US");
  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  chopperList: any;
  chopperLength: number = 0;
  p_myProfils: number = 1;
  p_myAskProfils: number = 1;
  spinnerNameChopper: boolean = false;
  spinnerNameProfil: boolean = false;
  test = 55;
  chopperState: boolean = true;
  demandeProfilListState: boolean = true;
  listCandidatState: boolean = true;
  desabledBtn;
  desabledBtn1;

  //Chopper
  pages: Array<number>;
  page: number = 0;
  isFirst = false;
  isLast = false;
  totalPages: number;

  //Ask profile
  pagesAsk: Array<number>;
  pageAsk: number = 0;
  isFirstAsk = false;
  isLastAsk = false;
  totalPagesAsk: number;

  constructor(
    private askProfileService: AskProfileService,
    private route: Router,
    private chopperCandidatService: ChopperCandidatService,
    public settingsService: SettingsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private spinner2: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.demandeProfil = new DemandeProfil();
    this.list_ask_profile();
    this.list_chopper();
  }

  list_ask_profile() {
    // this.spinner2.show(this.spinnerNameProfil);
    this.demandeProfilList = [];
    this.demandeProfilListState = true;
    this.spinnerNameProfil = false;
    let entrepriseAccount = {
      oid: atob(localStorage.getItem("entreprise_oid")),
    };
    this.askProfileService.list_ask_profile(entrepriseAccount, this.pageAsk).subscribe({
      next: (response) => {
        this.demandeProfilList = response["content"] as Array<DemandeProfil>;
        this.pagesAsk = new Array(response["totalPages"]);
        this.isFirstAsk = response["first"];
        this.isLastAsk = response["last"];
        this.spinnerNameChopper = true;

        // this.spinner2.hide(this.spinnerNameProfil);
        this.spinnerNameProfil = true;
        // this.demandeProfilList = response as Array<DemandeProfil>;
        this.demandeProfilListLength = this.demandeProfilList.length;
        if (this.demandeProfilList.length == 0) {
          this.demandeProfilListState = false;
        } else {
          this.demandeProfilListState = true;
        }
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  list_chopper() {
    // this.spinner.show(this.spinnerNameChopper);
    this.chopperState = true;
    this.chopperList = [];
    this.spinnerNameChopper = false;
    let entrepriseAccount = {
      oid: atob(localStorage.getItem("entreprise_oid")),
    };
    this.chopperCandidatService.list_chopper(entrepriseAccount, this.page).subscribe({
      next: (response) => {
        // this.spinner.hide(this.spinnerNameChopper);
        this.chopperList = response["content"];
        this.pages = new Array(response["totalPages"]);
        this.isFirst = response["first"];
        this.isLast = response["last"];
        this.spinnerNameChopper = true;
        // this.chopperList = response;
        this.chopperLength = this.chopperList.length;
        if (this.chopperList.length == 0) {
          this.chopperState = false;
        } else {
          this.chopperState = true;
        }
        // console.log(this.chopperList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  cancel_chopper(index, i) {
    this.desabledBtn = i;
    let chopper = index;

    if (confirm("Voulez vous vraiment supprimer/annuler cette demande?")) {
      this.chopperCandidatService.cancel_chopper(chopper).subscribe({
        next: (response) => {
          this.desabledBtn = null;
          this.toastr.success(response["message"], "Success");
          this.list_chopper();
        },
        error: (err) => {
          this.desabledBtn = null;
          console.log(err.error);
        },
      });
    } else {
      this.desabledBtn = null;
      return;
    }
  }

  delete_ask_profile(index, i) {
    this.desabledBtn1 = i;
    let chopper = index;

    if (confirm("Voulez vous vraiment supprimer/annuler cette demande?")) {
      this.askProfileService.delete_ask_profile(chopper).subscribe({
        next: (response) => {
          this.desabledBtn1 = null;
          this.toastr.success(response["message"], "Success");
          this.list_ask_profile();
        },
        error: (err) => {
          this.desabledBtn1 = null;
          console.log(err.error);
        },
      });
    } else {
      this.desabledBtn1 = null;
      return;
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.list_chopper();
  }

  rewind(): void {
    if (!this.isFirst) {
      this.page--;
      this.list_chopper();
    }
  }

  forward(): void {
    if (!this.isLast) {
      this.page++;
      this.list_chopper();
    }
  }

  setPageAks(i, event: any) {
    event.preventDefault();
    this.pageAsk = i;
    this.list_ask_profile();
  }

  rewindAsk(): void {
    if (!this.isFirstAsk) {
      this.pageAsk--;
      this.list_ask_profile();
    }
  }

  forwardAsk(): void {
    if (!this.isLastAsk) {
      this.pageAsk++;
      this.list_ask_profile();
    }
  }
}
