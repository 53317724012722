import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  private api = "https://mailthis.to/ajc-web-site";
  private customHttpClient: HttpClient;

  constructor(private http: HttpClient, backend: HttpBackend) {
    this.customHttpClient = new HttpClient(backend);
  }

  postMessage(input: any) {
    return this.customHttpClient
      .post(this.api, input, { responseType: "text" })
      .pipe(
        map(
          (response) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      );
  }
}
