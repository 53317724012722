import { Component, OnInit } from '@angular/core';
import { RegisterService } from 'src/app/services/candidat/register.service';

@Component({
  selector: 'app-our-missions',
  templateUrl: './our-missions.component.html',
  styleUrls: ['./our-missions.component.css']
})
export class OurMissionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
