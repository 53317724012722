import { SettingsService } from "./../../../services/settings/settings.service";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import { ContactService } from "../../../services/contact/contact.service";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-contact-us-form",
  templateUrl: "./contact-us-form.component.html",
  styleUrls: ["./contact-us-form.component.css"],
})
export class ContactUsFormComponent implements OnInit {
  sentBtnState: boolean = false;
  sentSpinnerState: boolean = true;
  sendMail = {
    status: null,
    response: null,
  };

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private contactService: ContactService,
    private http: HttpClient
  ) {}

  ngOnInit() {}

  constacttUsForm = this.formBuilder.group({
    name: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl("", Validators.required),
    message: new FormControl("", Validators.required),
  });

  get name() {
    return this.constacttUsForm.get("name");
  }

  get email() {
    return this.constacttUsForm.get("email");
  }

  get subject() {
    return this.constacttUsForm.get("subject");
  }

  get message() {
    return this.constacttUsForm.get("message");
  }

  contactUs() {
    if (this.constacttUsForm.valid) {
      this.sentSpinnerState = false;
      this.sentBtnState = true;
      let sendMailForm = {
        Email: this.constacttUsForm.get("email").value,
        subject: this.constacttUsForm.get("subject").value,
        Comment: this.constacttUsForm.get("message").value,
      };
      let sendEmail = {
        to: this.constacttUsForm.get("email").value,
        subject: this.constacttUsForm.get("subject").value,
        message: this.constacttUsForm.get("message").value,
      };
      console.log(sendMailForm);
      // const mailjet = require("node-mailjet").apiConnect(
      //   "187a360d37a95fbeb3127466221f491e",
      //   "76accc28b1fdf941b6d4d49aee2669d7"
      // );
      // const request = mailjet.post("send", { version: "v3.1" }).request({
      //   Messages: [
      //     {
      //       From: {
      //         Email: this.constacttUsForm.get("email").value,
      //         Name: this.constacttUsForm.get("name").value,
      //       },
      //       To: [
      //         {
      //           Email: "info@afrikajobsconsulting.com",
      //           Name: "AFRIKA JOB’S & CONSULTING",
      //         },
      //       ],
      //       Subject: "Greetings from Mailjet.",
      //       TextPart: "My first Mailjet email",
      //       HTMLPart:
      //         "<h3>Dear passenger 1, welcome to <a href='https://www.mailjet.com/'>Mailjet</a>!</h3><br />May the delivery force be with you!",
      //       CustomID: "AppGettingStartedTest",
      //     },
      //   ],
      // });
      // request
      //   .then((result) => {
      //     console.log(result.body);
      //   })
      //   .catch((err) => {
      //     console.log(err.statusCode);
      //   });

      this.http
        .post("https://formspree.io/f/xeqbnjyn", {
          name: this.constacttUsForm.get("name").value,
          replyto: this.constacttUsForm.get("email").value,
          message: this.constacttUsForm.get("message").value,
        })
        .subscribe((response) => {
          this.sentSpinnerState = true;
          this.sentBtnState = false;
          this.toastr.success("Votre mail a été envoyer", "Success");
          // console.log(response);
          this.constacttUsForm.reset();
        });

      // this.contactService.postMessage(sendMailForm).subscribe(
      //   (response) => {
      //     // location.href = "https://mailthis.to/confirm";
      //     this.sentSpinnerState = true;
      //     this.sentBtnState = false;
      //     this.toastr.success("Votre mail a été envoyer", "Success");
      //     console.log(response);
      //   },
      //   (error) => {
      //     console.warn(error.responseText);
      //     console.log({ error });
      //   }
      // );

      // this.settingsService.sendEmailWithMailJet(sendEmail).subscribe({
      //   next: (response) => {
      //     this.sentSpinnerState = true;
      //     this.sentBtnState = false;
      //     this.toastr.success("Votre mail a été envoyer", "Success");
      //   },
      //   error: (err) => {
      //     console.log(err.error);
      //   },
      // });
    } else {
      this.sendMail.status = false;
      this.sendMail.response = "Veillez remplir tous les champs obligatoire";
      $(".success-div").show();
      setTimeout(function () {
        $(".success-div").hide();
      }, 5000);
    }
  }
}
