import { Component, OnInit } from '@angular/core';
import { VisitService } from 'src/app/services/visit/visit.service';

@Component({
  selector: 'app-visit-counter',
  templateUrl: './visit-counter.component.html',
  styleUrls: ['./visit-counter.component.css']
})
export class VisitCounterComponent implements OnInit {

  public totalVisits: string = '000000';  // Valeur par défaut avec 6 zéros
  public displayedDigits: string[] = Array(6).fill('0');  // Initialisation du compteur affiché

  constructor(private visitService: VisitService) {}

  ngOnInit(): void {
    this.visitService.getVisitCount().subscribe(data => {
      let finalCount = 8500 + data.totalVisits;
      this.animateCounter(finalCount);
    });
  
    this.visitService.registerVisit('country').subscribe();
  }
  
  animateCounter(finalCount: number) {
    let currentCount = 0;
    const increment = Math.ceil(finalCount / 100);
    const interval = setInterval(() => {
      currentCount += increment;
      if (currentCount >= finalCount) {
        currentCount = finalCount;
        clearInterval(interval);
      }
      this.displayedDigits = currentCount.toString().padStart(6, '0').split('');
    }, 50);
  }  
}
