import { Component, OnInit } from "@angular/core";
import { UsersService } from "./services/userServices/users.service";
import { RegisterService } from "./services/candidat/register.service";
import { Users } from "./models/entreprise/users";
import { EntrepriseService } from "./services/entrepriseServices/entreprise/entreprise.service";
import { EntrepriseAccount } from "./models/entreprise/entreprise";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@angular/cdk/platform";
import { MatDialog } from "@angular/material";
import { LanguageModalComponent } from "./components/language-modal/language-modal.component";
import { ImageService } from "./services/image/image.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "ajc-fontend";
  candidatconnecter: String;
  users: Users;
  userconnecter: String;
  entrepriseAccount: EntrepriseAccount;
  name: String;
  logo: String;
  entreprise_oid: number;
  candidatName:string;
  selectedLanguage = 'en'; // Default language
  showModal = false
  languages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' },
    { code: 'ar', label: 'العربية' },
    { code: 'zh', label: '中文' },
    { code: 'es', label: 'Español'}
  ];

  images: any;

  constructor(
    public usersService: UsersService,
    private registerservice: RegisterService,
    public entrepriseServices: EntrepriseService,
    public entrepriseService: EntrepriseService,
    private toastr: ToastrService,
    private translate: TranslateService, 
    private imageService: ImageService
  ) {
    translate.addLangs(['fr', 'en', 'ar', 'zh']);
    translate.setDefaultLang(this.selectedLanguage);
  }

  ngOnInit(): void {
    this.users = new Users();
    this.registerservice.loadToken();
    // console.log(localStorage.getItem("entreprise_oid"))
    // console.log(localStorage.getItem('token'))
    this.entrepriseAccount = new EntrepriseAccount();
    this.users = new Users();
    this.images = this.imageService.getCurrentImages();
    // this.find_user();

    // Check if the site is opened on mobile
    if (window.innerWidth <= 768) {
      this.showModal = true;
    }
  }

  isAdmin() {
    return this.registerservice.isAdmin();
  }

  isAuthenticatedEntreprise() {
    // return this.registerservice.isAuthenticated();
    if (this.usersService.isLoggedIn()) {
      this.name = localStorage.getItem("name");
      this.logo = localStorage.getItem("logo");
      this.entreprise_oid = Number(
        atob(localStorage.getItem("entreprise_oid"))
      );
      // this.find_user();

      return true;
    } else {
      return false;
    }
  }

  isEntreprise() {
    return this.registerservice.isEntreprise();
  }

  isAuthenticated() {
    this.userconnecter = this.registerservice.candidat;
    this.candidatName = localStorage.getItem("username");
    return this.registerservice.isAuthenticated();
  }

  isCandidat() {
    return this.registerservice.isCandidat();
  }

  toDeconnected() {
    this.registerservice.toDeconnected();
    // window.location.reload();
  }

  // candadatConnected() {
  //   this.candidatconnecter = this.registerservice.candidatConnected();
  // }
  showSuccess() {
    this.toastr.success("Hello world!", "Toastr fun!");
  }

  find_user() {
    this.entrepriseServices.find_user().subscribe({
      next: (response) => {
        this.users = response as Users;
        // console.log(this.users);
      },
      error: (err1) => {
        console.log(err1.error);
      },
    });
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.imageService.setLanguage(lang);  // Change la langue
    this.images = this.imageService.getCurrentImages();  // Met à jour les images en fonction de la nouvelle langue
  }

  closeModal() {
    this.showModal = false;
  }
}
