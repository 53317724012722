// src/app/services/visit.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { myConst } from 'src/app/models/entreprise/settings';

@Injectable({
  providedIn: 'root'
})


export class VisitService {

  

  constructor(private http: HttpClient) {}

  // Récupérer le nombre total de visites
  getVisitCount(): Observable<any> { 
    return this.http.get(myConst.url.concat('/get-visit'));
  }

  // Enregistrer une visite
  registerVisit(country: string): Observable<any> {
    return this.http.post(myConst.url.concat('/save-visit?country=').concat(country), { country });
  }
}
