import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { myConst } from "../../../models/entreprise/settings";

@Injectable({
  providedIn: "root",
})
export class EntrepriseService {
  host = myConst.url;
  headers = { Authorization: "Bearer " + localStorage.getItem("token") };

  private customHttpClient: HttpClient;

  constructor(private http: HttpClient, backend: HttpBackend) {
    this.customHttpClient = new HttpClient(backend);
  }

  create_entreprise(entreprise: any) {
    return this.customHttpClient.post(
      myConst.url.concat("/create_entreprise"),
      entreprise
    );
  }

  create_entreprise_withoutLogo(entreprise: any) {
    return this.customHttpClient.post(
      myConst.url.concat("/create_entreprise_withoutLogo"),
      entreprise
    );
  }

  update_entreprise(entreprise: any) {
    return this.http.put(myConst.url.concat("/update_entreprise"), entreprise);
  }

  update_entreprise_withoutLogo(entreprise: any) {
    return this.http.put(
      myConst.url.concat("/update_entreprise_withoutLogo"),
      entreprise
    );
  }

  list_entrepriseAccounts() {
    return this.http.get(myConst.url.concat("/list_entrepriseAccounts"));
  }

  login(user: any) {
    return this.http.post(myConst.url.concat("/login"), user, {
      observe: "response",
    });
  }

  find_user() {
    return this.http.get(myConst.url.concat("/find_user"));
  }

  imageEntreprise(oid_entreprise) {
    return this.http.get(
      myConst.url.concat("/imageEntreprise/" + oid_entreprise)
    );
  }

  defaultEntrepriseImage() {
    return "assets/img/alternance.png";
  }

  activateEntreprieAccounte(verifyAccount: any) {
    return this.customHttpClient.post(
      myConst.url.concat("/activateEntreprieAccounte"),
      verifyAccount
    );
  }
}
