import { FormControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { EntrepriseService } from "../../../services/entrepriseServices/entreprise/entreprise.service";
import {
  Pays,
  ActivitySectors,
  ErrorClass,
} from "../../../models/entreprise/settings";
import { RespoEntreprise } from "../../../models/entreprise/representants";
import { EntrepriseAccount } from "../../../models/entreprise/entreprise";
import { RegisterService } from "src/app/services/candidat/register.service";
import * as moment from "moment";
import { SettingsService } from "./../../../services/settings/settings.service";
import { ChopperCandidatService } from "../../../services/entrepriseServices/chopperCandidat/chopper-candidat.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { UsersService } from "src/app/services/userServices/users.service";

@Component({
  selector: "app-find-profiles",
  templateUrl: "./find-profiles.component.html",
  styleUrls: ["./find-profiles.component.css"],
})
export class FindProfilesComponent implements OnInit {
  @ViewChild("openModal") openModal: ElementRef;
  //Model
  pays: Pays;
  activitySectors: ActivitySectors;
  respoEntreprise: RespoEntreprise;
  entrepriseAccount: EntrepriseAccount;
  errorClass: ErrorClass;
  successClass;
  SuccessClass;
  listCandidat;
  listCandidatLength;
  age;
  paysList;
  desabledBtn;
  desabledBtnAsk;
  pages: Array<number>;
  page: number = 0;
  isFirst = false;
  i: number;
  isLast = false;
  totalPages: number;
  listActivitySectorCdtList;

  activitySector = [
    "Agroalimentaire",
    "Banque / Assurance / Finance",
    "Bois / Papier / Carton / Imprimerie",
    "BTP / Matériaux de construction",
    "Chimie / Parachimie",
    "Commerce / Négoce / Distribution",
    "Édition / Communication / Multimédia",
    "Électronique / Électricité",
    "Études et conseils",
    "Industrie pharmaceutique / Medical",
    "Informatique / Télécoms",
    "Machines et équipements / Automobile",
    "Métallurgie / Travail du métal",
    "Plastique / Caoutchouc",
    "Services aux entreprises",
    "Textile / Habillement / Chaussure / Coiffure",
    "Transports / Logistique",
    "Envenementiel",
    "Art et culture",
  ];

  //Model arrays
  activitySectorsList: ActivitySectors[];
  respoEntrepriseList: RespoEntreprise[];
  entrepriseAccountList: EntrepriseAccount[];

  //Another proprieties
  p_profile: number = 1;
  listCandidatState: boolean = true;
  loadingState:boolean = true;

  constructor(
    private entrepriseServices: EntrepriseService,
    private router: Router,
    public registerservice: RegisterService,
    public chopperCandidatService: ChopperCandidatService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public settingsService: SettingsService,
    private usersService: UsersService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.pays = new Pays();
    this.entrepriseAccount = new EntrepriseAccount();
    this.respoEntreprise = new RespoEntreprise();
    // this.find_user();
    // this.list_candidat();
    this.filterCandidat();
    this.ListPaysCandidat();
    this.listActivitySectorCdt();
  }

  filterCdtForm = this.formbuilder.group({
    activitySectors: new FormControl(),
    pays: new FormControl(),
    ville: new FormControl(),
    // expNumber: new FormControl(),
  });

  list_candidat() {
    this.spinner.show();
    this.listCandidat = [];
    this.listCandidatState = true;
    this.loadingState = true;
    this.registerservice.list_candidat(this.page).subscribe(
      (response) => {
        this.spinner.hide();
        this.listCandidat = response["content"];
        this.pages = new Array(response["totalPages"]);
        this.isFirst = response["first"];
        this.isLast = response["last"];
        this.loadingState = false;
        // this.listCandidat = response;
        this.listCandidatLength = this.listCandidat.length;
        if (this.listCandidatLength == 0) {
          this.listCandidatState = false;
        } else {
          this.listCandidatState = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public CalculateAge(birthdate): number {
    return moment().diff(birthdate, "years");
  }

  find_user() {
    this.entrepriseServices.find_user().subscribe({
      next: (response) => {
        // console.log(response);
        this.entrepriseAccount = response as EntrepriseAccount;
        localStorage.setItem(
          "entreprise_oid",
          String(btoa(String(this.entrepriseAccount.oid)))
        );
        // console.log(response);
        // console.log(btoa(String(this.entrepriseAccount.oid)));
        // console.log(atob(localStorage.getItem("entreprise_oid")));
      },
      error: (err1) => {
        console.log(err1.error);
      },
    });
  }

  find_profile() {
    this.router.navigate(["candidate/profile-detail"]);
  }

  create_chopper(index, i) {
    if (this.usersService.isLoggedInEntreprise()) {
      this.desabledBtn = i;
      let chopper = {
        entrepriseAccount: {
          oid: Number(atob(localStorage.getItem("entreprise_oid"))),
        },
        candidat: { oid: index.oid },
      };
      // console.log(chopper);
      this.chopperCandidatService.create_chopper(chopper).subscribe({
        next: (response) => {
          this.desabledBtn = null;
          this.toastr.success(response["message"], "Success");
          // alert(response["message"]);
        },
        error: (err) => {
          this.desabledBtn = null;
          console.log(err.error);
        },
      });
    } else {
      this.openModal.nativeElement.click();
    }
  }

  testToast() {
    // alert("ceci est un test")
    this.toastr.success("Hello world!", "Hello world!");
  }

  testSpinner() {
    this.spinner.show("sp1", {
      bdColor: "rgba(51,51,51,0.8)",
      type: "line-scale-party",
      size: "large",
      // bdColor: "rgba(0, 0, 0, 1)",
      color: "white",
    });
  }

  ListPaysCandidat() {
    this.settingsService.ListPaysCandidat().subscribe({
      next: (response) => {
        this.paysList = response;
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  filterCandidat() {
    this.spinner.show();
    this.listCandidatState = true;
    this.loadingState = true;
    this.listCandidat = [];
    // let filterParams = {
    //   activitySectors: this.filterCdtForm.get("activitySectors").value,
    //   pays: this.filterCdtForm.get("pays").value,
    //   ville: this.filterCdtForm.get("ville").value,
    //   // expNumber: this.filterCdtForm.get("expNumber").value,
    // };
    let filterParams = {
      offresEmploisPage: {
        pageNumber: this.page,
        pageSize: 10,
      },
      candidatSearchCriteria: {
        activitySectors: this.filterCdtForm.get("activitySectors").value,
        pays: this.filterCdtForm.get("pays").value,
        ville: this.filterCdtForm.get("ville").value,
      },
    };
    // console.log(filterParams);
    this.registerservice.filterCandidat(filterParams).subscribe(
      (response) => {
        this.spinner.hide();
        // console.log(response);
        this.listCandidat = response["content"];
        this.pages = new Array(response["totalPages"]);
        this.isFirst = response["first"];
        this.isLast = response["last"];
        this.listCandidatLength = this.listCandidat.length;
        this.loadingState = false;
        if (this.listCandidatLength == 0) {
          this.listCandidatState = false;
        } else {
          this.listCandidatState = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.filterCandidat();
  }

  rewind(): void {
    if (!this.isFirst) {
      this.page--;
      this.filterCandidat();
    }
  }

  forward(): void {
    if (!this.isLast) {
      this.page++;
      this.filterCandidat();
    }
  }

  listActivitySectorCdt() {
    this.registerservice.listActivitySectorCdt().subscribe(
      (data) => {
        this.listActivitySectorCdtList = data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
