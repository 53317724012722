import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forbidden-errors',
  templateUrl: './forbidden-errors.component.html',
  styleUrls: ['./forbidden-errors.component.css']
})
export class ForbiddenErrorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
