import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { UsersService } from "../../../services/userServices/users.service";
import * as $ from "jquery";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPwdSpinnerState: boolean = true;
  forgotPwdBtnState: boolean = false;
  responseMessage = {
    status: null,
    message: null,
  };
  constructor(
    private formBuilder: FormBuilder,
    private usersSerivice: UsersService
  ) {}

  ngOnInit() {}
  forgotPwdForm = this.formBuilder.group({
    identifiant: new FormControl(null, Validators.required),
  });

  get identifiant() {
    return this.forgotPwdForm.get("identifiant");
  }

  save_forgotPwd() {
    if (this.forgotPwdForm.valid) {
      this.forgotPwdBtnState = true;
      this.forgotPwdSpinnerState = false;
      let paremForgotPwd = {
        identifiant: this.forgotPwdForm.get("identifiant").value,
      };
      this.usersSerivice.forgotEntreprisePassword(paremForgotPwd).subscribe({
        next: (response) => {
          if (response["status"] == true) {
            this.forgotPwdBtnState = false;
            this.forgotPwdSpinnerState = true;
            this.responseMessage.status = true;
            this.responseMessage.message =
              "Un mail a été envoyer dans la boite mail de/des responsable(s) du compte; bien vouloir le consulter. Si vous ne le voyez pas dans la boite de reception, merci de verifier vos Spams ou le menu Promotions";
          } else {
            this.forgotPwdBtnState = false;
            this.forgotPwdSpinnerState = true;
            this.responseMessage.status = false;
            this.responseMessage.message = response["message"];
            $(".error-div").show();
            setTimeout(function () {
              $(".error-div").hide();
            }, 5000);
          }
        },
        error: (err) => {
          this.forgotPwdBtnState = false;
          this.forgotPwdSpinnerState = true;
          console.log(err.error);
        },
      });
    } else {
      return;
    }
  }
}
