import { EntrepriseAccount } from "./entreprise";
export class Users {
  oid: number;
  identifiant: string;
  role: string;
  password: string;
  re_password: string;
  entrepriseAccount: EntrepriseAccount;
  
}
