import { Component, OnInit } from "@angular/core";
import { CompetenceOffre } from "../../../models/entreprise/competenceOffre";
import { MissionsOffre } from "../../../models/entreprise/missionsOffre";
import { OffreEmplois } from "../../../models/entreprise/offreEmplois";
import { OffersService } from "../../../services/entrepriseServices/offers/offers.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SettingsService } from "src/app/services/settings/settings.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-my-publications",
  templateUrl: "./my-publications.component.html",
  styleUrls: ["./my-publications.component.css"],
})
export class MyPublicationsComponent implements OnInit {
  competenceOffre: CompetenceOffre;
  missionsOffre: MissionsOffre;
  offreEmplois: OffreEmplois;

  //Array Model
  competenceOffreList: CompetenceOffre[];
  missionsOffreList: MissionsOffre[];
  offreEmploisList: OffreEmplois[];

  //Another proprieties
  offre_oid = this.router.snapshot.paramMap.get("offre_oid");
  p_myPub: number = 1;
  spinnerNamePub: boolean = true;
  pubState: boolean = false;
  desabledBtn;
  pages: Array<number>;
  page: number = 0;
  isFirst = false;
  isLast = false;
  totalPages: number;

  constructor(
    public offersService: OffersService,
    private route: Router,
    private router: ActivatedRoute,
    private toastr: ToastrService,
    public settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.competenceOffre = new CompetenceOffre();
    this.missionsOffre = new MissionsOffre();
    this.offreEmplois = new OffreEmplois();

    this.list_my_offre();
  }

  list_my_offre() {
    this.offreEmploisList = [];
    this.spinnerNamePub = true;
    let entrepriseAccount = {
      oid: atob(localStorage.getItem("entreprise_oid")),
    };
    // console.log(entrepriseAccount)
    this.offersService
      .list_my_offre(
        Number(atob(localStorage.getItem("entreprise_oid"))),
        this.page
      )
      .subscribe({
        next: (response) => {
          this.offreEmploisList = response["content"] as Array<OffreEmplois>;
          this.pages = new Array(response["totalPages"]);
          this.isFirst = response["first"];
          this.isLast = response["last"];

          this.spinnerNamePub = false;
          // this.offreEmploisList = response as Array<OffreEmplois>;
          if (this.offreEmploisList.length == 0) {
            this.pubState = true;
          } else {
            this.pubState = false;
          }
        },
        error: (err) => {
          // this.spinnerNamePub = true;
          this.spinnerNamePub = false;
          console.log(err.error);
        },
      });
  }

  supprimer_offre(index, i) {
    this.desabledBtn = i;
    if (confirm("voullez vous vraiment effectuer cette suppression?")) {
      this.offreEmplois = index;
      this.offersService.supprimer_offre(this.offreEmplois).subscribe({
        next: (response) => {
          this.desabledBtn = null;
          // alert(response["message"]);
          this.toastr.success(response["message"], "succès");
          this.list_my_offre();
        },
        error: (err) => {
          this.desabledBtn = null;
          console.log(err.error);
        },
      });
    } else {
      this.desabledBtn = null;
      return;
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.list_my_offre();
  }

  rewind(): void {
    if (!this.isFirst) {
      this.page--;
      this.list_my_offre();
    }
  }

  forward(): void {
    if (!this.isLast) {
      this.page++;
      this.list_my_offre();
    }
  }
}
