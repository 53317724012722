import { map } from "rxjs/operators";
import { myConst } from "./../../models/entreprise/settings";
import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UsersService } from "../userServices/users.service";

@Injectable({
  providedIn: "root",
})
export class RegisterService {
  private customHttpClient: HttpClient;
  public host = myConst.url;

  jwt: string;
  candidat: string;
  roles: Array<string>;
  tokenType: String = "Bearer";

  constructor(
    private http: HttpClient,
    backend: HttpBackend,
    public usersService: UsersService
  ) {
    this.customHttpClient = new HttpClient(backend);
  }

  add_candidat(candidat) {
    //console.log(candidat)
    return this.customHttpClient.post(this.host + "/add_candidat", candidat);
  }
  edit_candidat(candidat) {
    //console.log(candidat)
    return this.http.post(this.host + "/edit_candidat", candidat);
  }

  list_candidat(page: number) {
    return this.customHttpClient.get(this.host + "/list_candidat?page=" + page);
  }

  load_candidat() {
    //console.log(this.tokenType+' '+this.getToken())
    return this.http.get(this.host + "/load_candidat");
  }

  connexion_candidat(user) {
    return this.http.post(this.host + "/login", user, { observe: "response" });
  }

  detail_one_candidat(oidcandidat) {
    return this.customHttpClient.get(
      this.host + "/detail_one_candidat/" + oidcandidat
    );
  }

  uploadFileCv(dataform) {
    // console.log(dataform);
    return this.http.post(this.host + "/upload_cv", dataform, {
      // observe: "response",
    });
  }

  deleteFileCv(oid) {
    // console.log(filename);
    let params = new HttpParams();
    params = params.set("oid", oid);
    return this.http.post(this.host + "/delete_cv", params, {
      // observe: "response",
    });
  }

  add_competence(competence) {
    return this.http.post(this.host + "/add_competence", competence);
  }

  edit_competence(competence) {
    return this.http.post(this.host + "/edit_competence", competence);
  }

  dell_competence(competence) {
    return this.http.post(this.host + "/dell_competence", competence);
  }

  add_scolarite(scolarite) {
    return this.http.post(this.host + "/add_scolarite", scolarite);
  }

  edit_scolarite(scolarite) {
    return this.http.post(this.host + "/edit_scolarite", scolarite);
  }

  dell_scolarite(scolarite) {
    return this.http.post(this.host + "/dell_scolarite", scolarite);
  }

  add_experience(experience) {
    return this.http.post(this.host + "/add_experience", experience);
  }

  edit_experience(experience) {
    return this.http.post(this.host + "/edit_experience", experience);
  }

  dell_experience(experience) {
    return this.http.post(this.host + "/dell_experience", experience);
  }

  add_loisir(loisir) {
    return this.http.post(this.host + "/add_loisir", loisir);
  }

  edit_loisir(loisir) {
    return this.http.post(this.host + "/edit_loisir", loisir);
  }

  dell_loisir(loisir) {
    return this.http.post(this.host + "/dell_loisir", loisir);
  }

  add_langue(langue) {
    return this.http.post(this.host + "/add_langue", langue);
  }

  edit_langue(langue) {
    return this.http.post(this.host + "/edit_langue", langue);
  }

  dell_langue(langue) {
    return this.http.post(this.host + "/dell_langue", langue);
  }

  list_countrie() {
    return this.customHttpClient.get(this.host + "/list_pays");
  }

  myPostulerOffert(myPostulerOffrerParem) {
    return this.http.post(
      this.host + "/myPostulerOffert",
      myPostulerOffrerParem
    );
  }

  cancelMyPostulerOffert(postulerOffre) {
    return this.http.put(this.host + "/cancelMyPostulerOffert", postulerOffre);
  }

  create_candidatSpontane(data) {
    return this.customHttpClient.post(
      this.host + "/create_candidatSpontane",
      data
    );
  }

  getCvCandidat(id) {
    return this.http
      .get(myConst.url.concat("/getCvCandidat/" + id), {
        responseType: "blob",
        observe: "response",
      })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: "application/pdf" });
        })
      );
  }

  uploadCdtCv(data) {
    return this.http.post(this.host + "/uploadCdtCv", data);
  }

  listActivitySectorCdt() {
    return this.customHttpClient.get(this.host + "/list_ActivitySector");
  }

  getToken() {
    return localStorage.getItem("token");
  }

  saveToken(jwt1: string) {
    localStorage.setItem("token", jwt1);
    this.jwt = jwt1;
  }

  parseJWT() {
    let jwtHelper = new JwtHelperService();
    let objJWT = jwtHelper.decodeToken(this.jwt);
    this.candidat = objJWT.sub;
    //console.log(this.candidat)
    this.roles = objJWT.roles;
  }

  loadToken() {
    this.jwt = localStorage.getItem("token");
    // console.log(this.jwt);
    if (this.tokenExpired(this.jwt) === true || this.jwt === null) {
      this.initParam();
    } else {
      this.parseJWT();
    }
  }

  isAuthenticatedEntreprise() {
    // alert("yo")
    if (this.usersService.isLoggedIn()) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    return this.roles.indexOf("ADMIN") >= 0;
  }



  isEntreprise() {
    // alert("yo")
    // console.log(this.roles.indexOf("Entreprise") >= 0)
    return this.roles.indexOf("Entreprise") >= 0;
  }


  isCandidat() {
    return this.roles.indexOf("CANDIDAT") >= 0;
  }

  isAuthenticated() {
    this.loadToken();
    return this.roles;
  }

  toDeconnected() {
    localStorage.removeItem("token");
    localStorage.clear();
    this.initParam();
  }

  initParam() {
    this.jwt = undefined;
    this.candidat = undefined;
    this.roles = undefined;
  }

  tokenExpired(token: string) {
    let statuttoken: Boolean;
    let jwtHelper = new JwtHelperService();
    if (jwtHelper.isTokenExpired(token)) {
      // console.log("Token est expirer")
      statuttoken = true;
    } else {
      //console.log("Token pas expirer")
      statuttoken = false;
    }

    return statuttoken;
  }

  filterCandidat(paremCdtFilter) {
    return this.customHttpClient.post(
      myConst.url.concat("/filterCandidat"),
      paremCdtFilter
    );
  }
}
