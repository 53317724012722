import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RegisterService } from "src/app/services/candidat/register.service";
import * as moment from "moment";
import { ChopperCandidatService } from "../../../services/entrepriseServices/chopperCandidat/chopper-candidat.service";
import { UsersService } from "src/app/services/userServices/users.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-profile-detail",
  templateUrl: "./profile-detail.component.html",
  styleUrls: ["./profile-detail.component.css"],
})
export class ProfileDetailComponent implements OnInit {
  @ViewChild("openModal") openModal: ElementRef;
  detailcandidat;
  idcandidat;

  constructor(
    public registerservice: RegisterService,
    private activatedroute: ActivatedRoute,
    public chopperCandidatService: ChopperCandidatService,
    private usersService: UsersService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.idcandidat = this.activatedroute.snapshot.paramMap.get("idcandidat");
    this.detailCandidat();
  }

  detailCandidat() {
    this.registerservice.detail_one_candidat(this.idcandidat).subscribe(
      (response) => {
        this.detailcandidat = response;
        // console.log(this.detailcandidat);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ChaineVersArray(chaine: String) {
    let ch = chaine.split("\n");
    return ch;
  }

  public CalculateAge(birthdate): number {
    return moment().diff(birthdate, "years");
  }

  store_chopper(index: any) {
    if (this.usersService.isLoggedInEntreprise()) {
      let chopper = {
        entrepriseAccount: {
          oid: Number(atob(localStorage.getItem("entreprise_oid"))),
        },
        candidat: { oid: index.oid },
      };
      // console.log(chopper);
      this.chopperCandidatService.create_chopper(chopper).subscribe({
        next: (response) => {
          this.toastr.success(response["message"], "Success");
          // alert(response["message"]);
        },
        error: (err) => {
          console.log(err.error);
        },
      });
    } else {
      this.openModal.nativeElement.click();
    }
  }
}
