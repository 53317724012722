import { HttpBackend, HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterService } from "src/app/services/candidat/register.service";
import { UsersService } from "src/app/services/userServices/users.service";
import { EntrepriseAccount } from "../../../models/entreprise/entreprise";
import { EntrepriseService } from "../../../services/entrepriseServices/entreprise/entreprise.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  candidat;
  candidatlog;
  user;
  loginBtnState: boolean = false;
  loginSpinnerState: boolean = true;
  errorMessage = {
    status: null,
    message: null,
  };

  constructor(
    private fb: FormBuilder,
    private registerservice: RegisterService,
    private entrepriseServices: EntrepriseService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      identifiant: "",
      pwd: "",
    });
  }

  onConnexion() {
    // console.log(this.loginForm)
    const redirecturl =
      this.activatedRoute.snapshot.queryParamMap.get("redirectUrl") ||
      "candidate/find-profile";
    this.loginBtnState = true;
    this.loginSpinnerState = false;
    let user = {
      identifiant: this.loginForm.value.identifiant,
      password: this.loginForm.value.pwd,
    };

    this.registerservice.connexion_candidat(user).subscribe(
      (response) => {
        this.loginBtnState = false;
        this.loginSpinnerState = true;
        let jwt = response.headers.get("Authorization");
        this.registerservice.saveToken(jwt);

        this.entrepriseServices.find_user().subscribe({
          next: (response1) => {
            this.user = response1;
            // localStorage.setItem("logo", this.users.entrepriseAccount.logo);
            // localStorage.setItem(
            //   "username",
            //   this.user.candidat.nom + " " + this.user.candidat.prenom
            // );
            localStorage.setItem("username", this.user.candidat.nom);
            // console.log(localStorage.getItem("username"))
            localStorage.setItem(
              "candidat_oid",
              String(btoa(String(this.user.candidat.oid)))
            );
            localStorage.setItem(
              "userId",
              String(btoa(String(this.user.oid)))
            );
            // this.router.navigate(["entreprise/list-offers"]);
            this.router.navigateByUrl(redirecturl);
          },
          error: (err1) => {
            this.loginBtnState = false;
            this.loginSpinnerState = true;
            console.log(err1.error);
          },
        });

        // this.router.navigate(['/candidat/setcv']);
      },
      (err) => {
        this.loginBtnState = false;
        this.loginSpinnerState = true;
        this.errorMessage.message = "Email ou mot de passe incorrect";
        this.errorMessage.status = 403;
        console.log(err.error);
      }
    );
  }

  isAdmin() {
    return this.registerservice.isAdmin();
  }

  isCandidat() {
    return this.registerservice.isCandidat();
  }
}
