import { Component, OnInit } from "@angular/core";
import { RegisterService } from "src/app/services/candidat/register.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl } from "@angular/forms";
import { SettingsService } from "src/app/services/entrepriseServices/settings/settings.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "src/app/services/image/image.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  userconnecter;
  activitySectorsList: any;
  paysList: any;
  images: any;

  constructor(
    private registerservice: RegisterService,
    private toastr: ToastrService,
    private formbulder: FormBuilder,
    private settings: SettingsService,
    private translate: TranslateService,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    this.list_ActivitySector();
    this.getCountriesListInAlphabetical();
    this.images = this.imageService.getCurrentImages();
  }

  homeForm = this.formbulder.group({
    activitySector: new FormControl(),
    pays: new FormControl(),
  });

  isAuthenticated() {
    this.userconnecter = this.registerservice.candidat;
    return this.registerservice.isAuthenticated();
  }

  isCandidat() {
    return this.registerservice.isCandidat();
  }

  list_ActivitySector() {
    this.settings.list_ActivitySector().subscribe({
      next: (response) => {
        this.activitySectorsList = response;
        // console.log(this.activitySectorsList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  getCountriesListInAlphabetical() {
    this.settings.getCountriesListInAlphabetical().subscribe({
      next: (response) => {
        this.paysList = response;
        //console.log(this.paysList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  onLanguageChange(language: string) {
    this.imageService.setLanguage(language);  // Change la langue
    this.images = this.imageService.getCurrentImages();  // Met à jour les images en fonction de la nouvelle langue
  }
}
