import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormControl } from "@angular/forms";
import { SettingsService } from "./../../../services/settings/settings.service";
import { MissionsOffre } from "./../../../models/entreprise/missionsOffre";
import { CompetenceOffre } from "./../../../models/entreprise/competenceOffre";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { OffreEmplois } from "../../../models/entreprise/offreEmplois";
import { OffersService } from "../../../services/entrepriseServices/offers/offers.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { myConst } from "../../../models/entreprise/settings";
import { RegisterService } from "src/app/services/candidat/register.service";
import { ToastrService } from "ngx-toastr";
import { ShareDataService } from "src/app/services/shareData/share-data.service";
import { UsersService } from "src/app/services/userServices/users.service";

@Component({
  selector: "app-list-offer",
  templateUrl: "./list-offer.component.html",
  styleUrls: ["./list-offer.component.css"],
})
export class ListOfferComponent implements OnInit {
  @ViewChild("openModal") openModal: ElementRef;
  competenceOffre: CompetenceOffre;
  missionsOffre: MissionsOffre;
  offreEmplois: OffreEmplois;

  //Array Model
  competenceOffreList: CompetenceOffre[];
  missionsOffreList: MissionsOffre[];
  offreEmploisList: OffreEmplois[];

  //Another proprieties
  offreFile: File;
  testImage;
  p_listOffre: number = 1;
  activitySectorsList;
  listOdffreLength;
  activitySectorsSelected = {
    oid: null,
    name: null,
  };
  paysList;
  listOffreState: boolean = false;
  desabledBtn;
  pages: Array<number>;
  page: number = 0;
  isFirst = false;
  isLast = false;
  totalPages: number;
  sharedData = {
    activitySector: null,
    pays: null,
  };

  loadingState: boolean = true;

  constructor(
    public offersService: OffersService,
    private router: Router,
    private http: HttpClient,
    private settings: SettingsService,
    public registerService: RegisterService,
    public settingsService: SettingsService,
    private toastr: ToastrService,
    private formbuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private shareDataService: ShareDataService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.competenceOffre = new CompetenceOffre();
    this.missionsOffre = new MissionsOffre();
    this.offreEmplois = new OffreEmplois();

    this.shareDataService.currentFilterData.subscribe(
      (msg) => (
        (this.sharedData.pays = msg.pays),
        (this.sharedData.activitySector = msg.activitySector)
      )
    );

    this.filteroffreForm.patchValue({
      activitySectors: this.sharedData.activitySector,
      pays: this.sharedData.pays,
    });

    // this.list_offre();
    // console.log(this.sharedData);
    this.listPaysOffre();
    this.listActivitySectorsOffre();
    this.filterOffre();
  }

  filteroffreForm = this.formbuilder.group({
    activitySectors: new FormControl(),
    pays: new FormControl(),
    ville: new FormControl(),
  });

  list_offre() {
    this.spinner.show();
    this.offreEmploisList = [];
    this.loadingState = true;
    this.offersService.listOffrePagination(this.page).subscribe({
      next: (response) => {
        // this.offreEmploisList = response as Array<OffreEmplois>;
        // this.totalPages = response["totalPages"]
        this.offreEmploisList = response["content"] as Array<OffreEmplois>;
        this.pages = new Array(response["totalPages"]);
        this.isFirst = response["first"];
        this.isLast = response["last"];
        this.spinner.hide();
        // console.log(response);
        this.loadingState = false;
        this.listOdffreLength = this.offreEmploisList.length;
        if (this.listOdffreLength == 0) {
          this.listOffreState = true;
        } else {
          this.listOffreState = false;
        }
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  imageOffre(oid_offre) {
    this.offersService.imageOffre(oid_offre).subscribe({
      next: (response) => {
        this.offreFile = response as File;
        // console.log(this.offreFile);
        return response;
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  getImageOffre(filename) {
    this.offersService.getImageOffre(filename).subscribe((res) => {
      this.testImage = URL.createObjectURL(res);
      // console.log(this.testImage);
      //window.open(fileURL, "_blank");
    });
  }

  defaultOffreImage() {
    return "assets/img/téléchargement (1).jpg";
  }

  postulerOffre(offreEmplois, i) {
    if (this.usersService.isLoggedInCdt()) {
      this.desabledBtn = i;
      let postulerOffre = {
        offreEmplois: { oid: offreEmplois.oid },
        candidat: { oid: Number(atob(localStorage.getItem("candidat_oid"))) },
      };
      this.offersService.postuler_offre(postulerOffre).subscribe({
        next: (response) => {
          this.desabledBtn = null;
          this.toastr.success(response["message"], "Success");
        },
        error: (err) => {
          this.desabledBtn = null;
          console.log(err.error);
        },
      });
    } else {
      this.openModal.nativeElement.click();
    }
  }

  listPaysOffre() {
    this.settings.listPaysOffre().subscribe({
      next: (response) => {
        this.paysList = response;
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  listActivitySectorsOffre() {
    this.settings.list_ActivitySector().subscribe({
      next: (response) => {
        this.activitySectorsList = response;
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  filterOffre() {
    this.spinner.show();
    this.offreEmploisList = [];
    this.listOffreState = false;
    this.loadingState = true;
    let paremOffreFilter = {
      offresEmploisPage: {
        pageNumber: this.page,
        pageSize: 10,
      },
      offresEmploisSearchCriteria: {
        activity: this.filteroffreForm.get("activitySectors").value,
        pays: this.filteroffreForm.get("pays").value,
        ville: this.filteroffreForm.get("ville").value,
      },
    };
    // console.log(paremOffreFilter);
    this.offersService.filterOffres(paremOffreFilter).subscribe({
      next: (response) => {
        // console.log(response);
        this.spinner.hide();
        this.offreEmploisList = response["content"] as Array<OffreEmplois>;
        this.pages = new Array(response["totalPages"]);
        this.isFirst = response["first"];
        this.isLast = response["last"];
        this.spinner.hide();
        this.loadingState = false;
        this.listOdffreLength = this.offreEmploisList.length;
        if (this.listOdffreLength == 0) {
          this.listOffreState = true;
        } else {
          this.listOffreState = false;
        }
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.filterOffre();
  }

  rewind(): void {
    if (!this.isFirst) {
      this.page--;
      this.filterOffre();
    }
  }

  forward(): void {
    if (!this.isLast) {
      this.page++;
      this.filterOffre();
    }
  }
}
