import { Component, OnInit } from "@angular/core";
import { SettingsService } from "src/app/services/settings/settings.service";
import { RegisterService } from "../../../services/candidat/register.service";
import { OffersService } from "../../../services/entrepriseServices/offers/offers.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-postuleroffre",
  templateUrl: "./postuleroffre.component.html",
  styleUrls: ["./postuleroffre.component.css"],
})
export class PostuleroffreComponent implements OnInit {
  postulerOffreList;
  spinnerNamePostuler: boolean = true;
  postulerState: boolean = true;
  datePipe = new DatePipe("en-US");
  p_listOffre: number = 1;
  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  pages: Array<number>;
  page: number = 0;
  isFirst = false;
  isLast = false;
  totalPages: number;

  constructor(
    private registerService: RegisterService,
    public offersService: OffersService,
    public settingsService: SettingsService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.myPostulerOffert();
  }

  myPostulerOffert() {
    this.postulerOffreList = [];
    let myPostulerOffrerParem = {
      candidat: {
        oid: Number(atob(localStorage.getItem("candidat_oid"))),
      },
      page: this.page,
    };
    this.spinnerNamePostuler = false;
    let candidat = {
      oid: Number(atob(localStorage.getItem("candidat_oid"))),
    };
    this.registerService.myPostulerOffert(myPostulerOffrerParem).subscribe({
      next: (response) => {
        this.spinnerNamePostuler = true;
        // this.postulerOffreList = response;

        this.postulerOffreList = response["content"];
        // console.log(response)
        this.pages = new Array(response["totalPages"]);
        this.isFirst = response["first"];
        this.isLast = response["last"];
        if (this.postulerOffreList.length == 0) {
          this.postulerState = false;
        } else {
          this.postulerState = true;
        }
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  cancelMyPostulerOffert(index) {
    // console.log(index.oid);
    let postulerOffre = {
      oid: index.oid,
    };
    if (confirm("Voulez vous vraiment annuler cette demande?")) {
      this.registerService.cancelMyPostulerOffert(postulerOffre).subscribe({
        next: (response) => {
          this.toastr.success(response["message"], "Success");
          this.myPostulerOffert();
        },
        error: (err) => {
          console.log(err.error);
        },
      });
    } else {
      return;
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.myPostulerOffert();
  }

  rewind(): void {
    if (!this.isFirst) {
      this.page--;
      this.myPostulerOffert();
    }
  }

  forward(): void {
    if (!this.isLast) {
      this.page++;
      this.myPostulerOffert();
    }
  }
}
