import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ShareDataService {
  private filterData = new BehaviorSubject({
    activitySector: null,
    pays: null,
  });
  currentFilterData = this.filterData.asObservable();

  constructor() {}

  updateFilterData(pays: any, activitySector: any) {
    this.filterData.next({
      pays: pays,
      activitySector: activitySector,
    });
  }
}
