import { Component, OnInit } from "@angular/core";
import { Pays, ErrorClass } from "../../../models/entreprise/settings";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { SettingsService } from "../../../services/entrepriseServices/settings/settings.service";
import { DatePipe } from "@angular/common";
import { RegisterService } from "../../../services/candidat/register.service";
import * as $ from "jquery";

@Component({
  selector: "app-spont-candidature",
  templateUrl: "./spont-candidature.component.html",
  styleUrls: ["./spont-candidature.component.css"],
})
export class SpontCandidatureComponent implements OnInit {
  //Model
  pays: Pays;
  errorClass: ErrorClass;

  //Model arrays
  paysList: Pays[];

  //Another proprieties
  civilityTab = [
    { id: "Homme", name: "Homme" },
    { id: "Femme", name: "Femme" },
    // { id: "Homme/Femme", name: "Homme/Femme" },
  ];

  matrimonialStatusTab = [
    { id: "Marié", name: "Marié" },
    { id: "Celibataire", name: "Celibataire" },
  ];

  datePipe = new DatePipe("en-US");
  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  cvFileSlected: File;
  file_statut: boolean = true;
  sponCdtState: boolean = false;
  sponCdtSpinnerState: boolean = true;
  errorMessage = {
    message: null,
    status: null,
  };

  constructor(
    private formbulder: FormBuilder,
    private settings: SettingsService,
    private registerService: RegisterService
  ) {}

  spontCdtForm = this.formbulder.group({
    // oid: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required),
    surname: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    phone: new FormControl("", Validators.required),
    birthDate: new FormControl("", Validators.required),
    civility: new FormControl(null, Validators.required),
    statutMatrimonial: new FormControl(null, Validators.required),
    motivation: new FormControl("", Validators.required),
    nationality: new FormControl("", Validators.required),
    pays: new FormControl(null, Validators.required),
    ville: new FormControl("", Validators.required),
    adress: new FormControl(),
    cv_file: new FormControl("", Validators.required),
  });

  ngOnInit() {
    this.errorClass = new ErrorClass();
    this.getCountriesListInAlphabetical();
  }

  get name() {
    return this.spontCdtForm.get("name");
  }

  get surname() {
    return this.spontCdtForm.get("surname");
  }

  get email() {
    return this.spontCdtForm.get("email");
  }

  get phone() {
    return this.spontCdtForm.get("phone");
  }

  get birthDate() {
    return this.spontCdtForm.get("birthDate");
  }

  get civility() {
    return this.spontCdtForm.get("civility");
  }

  get statutMatrimonial() {
    return this.spontCdtForm.get("statutMatrimonial");
  }

  get motivation() {
    return this.spontCdtForm.get("motivation");
  }

  get nationality() {
    return this.spontCdtForm.get("nationality");
  }

  get ville() {
    return this.spontCdtForm.get("ville");
  }

  get payss() {
    return this.spontCdtForm.get("pays");
  }

  get cv_file() {
    return this.spontCdtForm.get("cv_file");
  }

  onFileChangedCV(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if (file.type == "application/pdf") {
        this.cvFileSlected = event.target.files[0];
        this.file_statut = true;
      } else {
        this.file_statut = false;
        //call validation
        this.spontCdtForm.get("cv_file").reset();
        this.spontCdtForm.controls["cv_file"].setValidators([
          Validators.required,
        ]);
        this.spontCdtForm.get("cv_file").updateValueAndValidity();
      }
    }
  }

  getCountriesListInAlphabetical() {
    this.settings.getCountriesListInAlphabetical().subscribe({
      next: (response) => {
        this.paysList = response as Array<Pays>;
        // console.log(this.paysList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  save_sponCdt() {
    if (this.spontCdtForm.valid) {
      this.sponCdtState = true;
      this.sponCdtSpinnerState = false;
      let formDataSponCdt = new FormData();
      let candidatSpontane = {
        name: this.spontCdtForm.get("name").value,
        surname: this.spontCdtForm.get("surname").value,
        email: this.spontCdtForm.get("email").value,
        phone: this.spontCdtForm.get("phone").value,
        birthDate: this.spontCdtForm.get("birthDate").value,
        civility: this.spontCdtForm.get("civility").value,
        statutMatrimonial: this.spontCdtForm.get("statutMatrimonial").value,
        motivation: this.spontCdtForm.get("motivation").value,
        nationality: this.spontCdtForm.get("nationality").value,
        pays: this.spontCdtForm.get("pays").value,
        ville: this.spontCdtForm.get("ville").value,
        adress: this.spontCdtForm.get("adress").value,
        cv_file: null,
      };

      formDataSponCdt.append(
        "candidatSpontane",
        JSON.stringify(candidatSpontane)
      );
      formDataSponCdt.append(
        "cv_file",
        this.cvFileSlected,
        this.cvFileSlected.name
      );

      this.registerService.create_candidatSpontane(formDataSponCdt).subscribe({
        next: (response) => {
          this.sponCdtState = false;
          this.sponCdtSpinnerState = true;
          this.errorClass.message = response["message"];
          this.errorClass.status = response["status"];
          $(".success-div").show();
          setTimeout(function () {
            $(".success-div").hide();
          }, 7000);
        },
        error: (err) => {
          this.errorMessage.message =
            "Une erreur c'est produite! veillez reessayer plus tard";
          this.errorMessage.status = false;
          this.sponCdtState = false;
          this.sponCdtSpinnerState = true;
          $(".error-div").show();
          setTimeout(function () {
            $(".error-div").hide();
          }, 7000);
          console.log(err.error);
        },
      });
    } else {
      this.errorMessage.message =
        "Veillez remplir tous les champs obligatoires";
      this.errorMessage.status = false;
      $(".error-div").show();
      setTimeout(function () {
        $(".error-div").hide();
      }, 7000);
    }
  }
}
