import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterService } from "src/app/services/candidat/register.service";
import { EntrepriseService } from "../../../services/entrepriseServices/entreprise/entreprise.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-registercandidat",
  templateUrl: "./registercandidat.component.html",
  styleUrls: ["./registercandidat.component.css"],
})
export class RegistercandidatComponent implements OnInit {
  registerform: FormGroup;
  candidat;
  messageconfirmpassword;
  registerSpinnerState: boolean = true;
  user;
  errorMessage = {
    status: null,
    message: null,
  };

  constructor(
    private fb: FormBuilder,
    private candidatservice: RegisterService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private registerserve: RegisterService,
    private entrepriseServices: EntrepriseService
  ) {}

  ngOnInit() {
    this.registerform = this.fb.group({
      oid: "",
      nom: new FormControl("", [Validators.required, Validators.maxLength(15)]),
      prenom: ["", Validators.required],
      email: ["", Validators.required],
      password: ["", Validators.required],
      password1: ["", Validators.required],
      civilite: ["", Validators.required],
    });
  }

  onRegisterCandidat() {
    this.registerSpinnerState = false;
    // this.router.navigate(['/candidat/setcv']);
    let registered = this.registerform.value;
    if (registered.password != registered.password1) {
      this.messageconfirmpassword = "Les mots de passe sont diferent";
      this.registerSpinnerState = true;
    } else {
      this.messageconfirmpassword = "";

      this.candidatservice.add_candidat(this.registerform.value).subscribe(
        (response) => {
          // alert("Succès enregitrement");
          let user = {
            identifiant: this.registerform.value.email,
            password: this.registerform.value.password,
          };

          this.candidatservice.connexion_candidat(user).subscribe(
            (response1) => {
              let jwt = response1.headers.get("Authorization");
              this.candidatservice.saveToken(jwt);

              this.entrepriseServices.find_user().subscribe({
                next: (response2) => {
                  this.registerSpinnerState = true;
                  this.user = response2;
                  // console.log(response1);
                  // localStorage.setItem("logo", this.users.entrepriseAccount.logo);
                  localStorage.setItem(
                    "username",
                    this.user.candidat.nom + " " + this.user.candidat.prenom
                  );
                  // console.log(localStorage.getItem("username"))
                  localStorage.setItem(
                    "candidat_oid",
                    String(btoa(String(this.user.candidat.oid)))
                  );
                  this.router.navigate(["/candidat/setcv"]);
                },
                error: (err1) => {
                  this.registerSpinnerState = true;
                  console.log(err1.error);
                },
              });

              // this.router.navigate(['/candidat/setcv']);
            },
            (err) => {
              this.registerSpinnerState = true;
              console.log(err.error.massage);
            }
          );

          //this.registerserve.userconnectservice = response
          // this.router.navigate(['/candidat/setcv']);
          // this.router.navigate(["/candidat/login"]);
          //console.log(response)
        },
        (err) => {
          this.registerSpinnerState = true;
          // this.messageconfirmpassword = err;
          this.errorMessage.message =
            "Un utilisateur existe déja avec cet email";
          this.errorMessage.status = err.error.status;
          console.log(err.error);
        }
      );
    }
  }

  onVerifInitPasse() {
    this.messageconfirmpassword = "";
  }

  goToConnexion() {
    this.router.navigate(["/users"], {
      queryParams: { filter: "new" },
      queryParamsHandling: "merge",
    });
  }
}
