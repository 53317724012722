import { Injectable } from "@angular/core";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { myConst } from "../../models/entreprise/settings";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  datePipe = new DatePipe("en-US");
  private customHttpClient: HttpClient;

  constructor(private http: HttpClient, backend: HttpBackend) {
    this.customHttpClient = new HttpClient(backend);
  }

  public CalculateAge(birthdate): number {
    return moment().diff(birthdate, "years");
  }

  public convertTodate(timestamp) {
    return new Date(timestamp);
  }

  public sendEmail(sendEmail) {
    return this.customHttpClient.post(
      myConst.url.concat("/sendEmail"),
      sendEmail
    );
  }

  public sendEmailWithMailJet(sendEmail) {
    return this.customHttpClient.post(
      myConst.url.concat("/sendEmailWithMailJet"),
      sendEmail
    );
  }

  static hourValidator(hour): any {
    if (hour.pristine) {
      return null;
    }
    const hour_REGEXP =
      /^(10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|12|12|[1-9]):[0-5][0-9]$/;
    hour.markAsTouched();
    if (hour_REGEXP.test(hour.value)) {
      return null;
    }
    return {
      invalidhour: true,
    };
  }

  list_ActivitySector() {
    return this.customHttpClient.get(
      myConst.url.concat("/list_ActivitySector")
    );
  }

  getCountriesListInAlphabetical() {
    return this.customHttpClient.get(
      myConst.url.concat("/getCountriesListInAlphabetical")
    );
  }

  listPaysOffre() {
    return this.customHttpClient.get(myConst.url.concat("/getCountriesListInAlphabetical"));
  }

  listActivitySectorsOffre() {
    return this.customHttpClient.get(myConst.url.concat("/listActivitySectorsOffre"));
  }

  ListPaysCandidat() {
    return this.customHttpClient.get(myConst.url.concat("/getCountriesListInAlphabetical"));
  }
}
