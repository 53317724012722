import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management-rh',
  templateUrl: './management-rh.component.html',
  styleUrls: ['./management-rh.component.css']
})
export class ManagementRhComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
