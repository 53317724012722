import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { SettingsService } from "src/app/services/entrepriseServices/settings/settings.service";
import { ShareDataService } from "src/app/services/shareData/share-data.service";

@Component({
  selector: "app-home-filter",
  templateUrl: "./home-filter.component.html",
  styleUrls: ["./home-filter.component.css"],
})
export class HomeFilterComponent implements OnInit {
  userconnecter;
  activitySectorsList: any;
  paysList: any;
  message: string = " ";
  ApprovationText: string = "";

  constructor(
    private formbulder: FormBuilder,
    private settings: SettingsService,
    private shareDataService: ShareDataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.list_ActivitySector();
    this.getCountriesListInAlphabetical();
  }

  homeForm = this.formbulder.group({
    activitySector: new FormControl(),
    pays: new FormControl(),
  });

  list_ActivitySector() {
    this.settings.list_ActivitySector().subscribe({
      next: (response) => {
        this.activitySectorsList = response;
        // console.log(this.activitySectorsList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  getCountriesListInAlphabetical() {
    this.settings.getCountriesListInAlphabetical().subscribe({
      next: (response) => {
        this.paysList = response;
        //console.log(this.paysList);
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  homeFilter() {
    this.shareDataService.updateFilterData(
      this.homeForm.get("pays").value,
      this.homeForm.get("activitySector").value
    );
    this.router.navigate(["entreprise/list-offers"]);
  }
}
