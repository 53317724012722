import { UsersService } from "src/app/services/userServices/users.service";
import { ToastrService } from "ngx-toastr";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CompetenceOffre } from "../../../models/entreprise/competenceOffre";
import { MissionsOffre } from "../../../models/entreprise/missionsOffre";
import { OffreEmplois } from "../../../models/entreprise/offreEmplois";
import { OffersService } from "../../../services/entrepriseServices/offers/offers.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { RegisterService } from "src/app/services/candidat/register.service";

@Component({
  selector: "app-detail-offer",
  templateUrl: "./detail-offer.component.html",
  styleUrls: ["./detail-offer.component.css"],
})
export class DetailOfferComponent implements OnInit {
  @ViewChild("openModal") openModal: ElementRef;

  competenceOffre: CompetenceOffre;
  missionsOffre: MissionsOffre;
  offreEmplois: OffreEmplois;

  //Array Model
  competenceOffreList: CompetenceOffre[];
  missionsOffreList: MissionsOffre[];
  offreEmploisList: OffreEmplois[];

  //Another proprieties
  offre_oid = this.router.snapshot.paramMap.get("offre_oid");
  p_detailOffre: number = 1;
  desabledBtn;
  pages: Array<number>;
  page: number = 0;
  isFirst = false;
  isLast = false;
  totalPages: number;

  constructor(
    public offersService: OffersService,
    private route: Router,
    private router: ActivatedRoute,
    private location: Location,
    private toastr: ToastrService,
    private usersService: UsersService,
    public registerService: RegisterService
  ) {}

  ngOnInit() {
    this.competenceOffre = new CompetenceOffre();
    this.missionsOffre = new MissionsOffre();
    this.offreEmplois = new OffreEmplois();

    this.find_offre();
    // this.list_similary_offre();
  }

  find_offre() {
    this.offersService.find_offre(this.offre_oid).subscribe({
      next: (response) => {
        this.offreEmplois = response as OffreEmplois;
        // console.log(this.offreEmplois)
        this.list_similary_offre();
      },
      error: (err) => {
        console.log(err.error);
      },
    });
  }

  list_similary_offre() {
    this.offreEmploisList = [];
    let paremSimilaryOffer = {
      activitySectors: this.offreEmplois.activitySectors,
      pays: this.offreEmplois.pays,
    };
    this.offersService
      .list_similary_offre(paremSimilaryOffer, this.page)
      .subscribe({
        next: (response) => {
          this.offreEmploisList = response["content"] as Array<OffreEmplois>;
          this.pages = new Array(response["totalPages"]);
          this.isFirst = response["first"];
          this.isLast = response["last"];
          // this.offreEmploisList = response as Array<OffreEmplois>;
          // this.location.replaceState("/entreprise/detail-offers/1");
        },
        error: (err) => {
          console.log(err.error);
        },
      });
  }

  view_similary_offer(index) {
    this.offreEmplois = index;
    this.location.replaceState(
      "/entreprise/detail-offers/" + this.offreEmplois.oid
    );
  }

  postulerOffre(offreEmplois) {
    if (this.usersService.isLoggedInCdt()) {
      let postulerOffre = {
        offreEmplois: { oid: offreEmplois.oid },
        candidat: { oid: Number(atob(localStorage.getItem("candidat_oid"))) },
      };
      this.offersService.postuler_offre(postulerOffre).subscribe({
        next: (response) => {
          this.desabledBtn = null;
          this.toastr.success(response["message"], "Success");
        },
        error: (err) => {
          this.desabledBtn = null;
          console.log(err.error);
        },
      });
    } else {
      this.openModal.nativeElement.click();
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.list_similary_offre();
  }

  rewind(): void {
    if (!this.isFirst) {
      this.page--;
      this.list_similary_offre();
    }
  }

  forward(): void {
    if (!this.isLast) {
      this.page++;
      this.list_similary_offre();
    }
  }
}
